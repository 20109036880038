import theme from "assets/theme";

export const FILTERS = {
  SECTOR: "sector",
  CATEGORY: "category",
  SUBCATEGORY: "subcategory",
  INDICATOR: "indicator",
  COUNTRY: "country",
  SUPPLY_CHAIN_COUNTRY: "supplyChainCountry",
  SUPPLY_CHAIN_SECTOR: "supplyChainSector",
};

export const NEW_ITEMS = {
  MATERIALS: "materials",
};

export const MAP = {
  DEFAULT_COUNTRY_COLOR: `${theme.map.default}`,
};

export const RISKS = {
  ND: {
    NAME: "No data",
    COLOR: `${theme.map.noData}`,
    BAR_WIDTH: 100,
  },
  NE: {
    NAME: "No evidence",
    COLOR: `${theme.map.noEvidence}`,
    BAR_WIDTH: 100,
  },
  LR: {
    NAME: "Low",
    COLOR: `${theme.green.light}`,
    BAR_WIDTH: 25,
  },
  MR: {
    NAME: "Medium",
    COLOR: `${theme.yellow.default}`,
    BAR_WIDTH: 50,
  },
  HR: {
    NAME: "High",
    COLOR: `${theme.red.light}`,
    BAR_WIDTH: 75,
  },
  VH: {
    NAME: "Very high",
    COLOR: `${theme.red.default}`,
    BAR_WIDTH: 100,
  },
};

export const CHARTS = {
  COLOR_PALETTE: [
    "#8FCCBC",
    "#E6B35E",
    "#5A9FEE",
    "#5AB85C",
    "#B34E65",
    "#876ECF",
    "#01C7D4",
    "#4A90E2",
    "#E6B35E",
    "#FA87AE",
    "#7ED322",
    "#014dad",
    "#b9b9b9",
    "#ed561b",
    "#b34e65",
    "#b3de59",
    "#1aadce",
    "#910000",
    "#8bbc21",
    "#0d233a",
    "#2f7ed8",
    "#6af9c4",
    "#fff263",
    "#ff9655",
    "#64e572",
    "#24cbe5",
    "#dddf00",
    "#ed561b",
    "#e1e7e7",
    "#e773ff",
    "#883a3a",
    "#5a3a88",
    "#155352",
  ],
  BAR: {
    HEIGHT: 14, // px
    PADDING: 0,
  },
  PIE: {
    SIZE: 50, // includes width and height (px)
    PADDING: 0, // px
    ANIMATION_DURATION: 200, // ms
    INNER_RADIUS: 0,
  },
  TOTAL_RISKS: 1000,
};

export const REQUEST_TYPES = {
  COMPARE_RISKS: "COMPARE_RISKS",
  COUNTRY_SPECIFIC_SECTORS: "COUNTRY_SPECIFIC_SECTORS",
  EXPLORE_RISK_FILTERS: "EXPLORE_RISK_FILTERS",
  CREATE_PRODUCT: "CREATE_PRODUCT",
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCT: "GET_PRODUCT",
  REMOVE_PRODUCT: "REMOVE_PRODUCT",
  GET_PRODUCT_TEMPLATE: "GET_PRODUCT_TEMPLATE",
  EDIT_PRODUCT: "EDIT_PRODUCT",
  LOGIN: "LOGIN",
  GET_NETWORK: "GET_NETWORK",
  SOCIAL_FOOTPRINT: "SOCIAL_FOOTPRINT",
};

export const INVENTORY = {
  SUBSTANCE_HEADER_WIDTH: "300px",
  COLUMN_WIDTH: "180px",
  VALUE_PRECISION: 5,
  MAX_HEADER_TITLE_LENGTH: 15,
};

export const MATERIAL_FORM_TYPES = {
  EDITING: "editing",
  ADDING: "adding",
};

export const STRINGS = {
  NO_DATA: "No data available.",
  NUMBER_SIGN: "\u2116",
  NO_COUNTRY_CHOSEN: "No country chosen",
  UNKNOWN: "Unknown",
  UNKNOWN_SECTOR: "Unknown sector",
  PRODUCT: "product",
  COUNTRY_SPECIFIC_SECTOR: "country_specific_sector",
  EDIT_MATERIAL: "Edit",
  ADD_MATERIAL: "Add material",
  ANALYZE: "analyze",
};

export const CURRENCY = {
  USD: {
    NAME: "USD",
    ICON: "$",
  },
};

export const COMPARE_PAGE_TABS = {
  MULTIPLY_BAR_CHARTS: "multiplyBarCharts",
  SOCIAL_HOTSPOT_INDEX: "socialHotspotIndex",
};

export const RISK_TYPES = {
  RISKS_HOTSPOTS: "risksHotspots",
  COMPARE_RISKS: "compareRisks",
};

export const COMPARE_FILTERS_TYPES = {
  COUNTRY: "country",
  SECTOR: "sector",
};

export const REQUEST_METHODS = {
  PUT: "put",
  DELETE: "delete",
  GET: "get",
  POST: "post",
};
