import React, { Fragment, } from "react";
import { get, } from "lodash";
import { Tooltip, } from "antd";
import { getRiskBarWidth, getRiskColor, getSectorNameByCode, } from "utils/helpers";
import { STRINGS, } from "constants";
import ArrowDown from "icons/ArrowDown";
import {
  Category,
  Item,
  RiskTitle,
  RiskLevel,
  Indicator,
  Collapse,
  CollapsePanel,
  NoData,
} from "./styled-components";

export const getSectorName = (sector, sectors) =>
  sector ? getSectorNameByCode(sector.code, sectors) : "No sector choosen";

export const isCountryComparing = (country, countries, sectorName) =>
  countries.some(c => c.NAME === country.NAME && c.sector.name === sectorName);

export const composeCountryObjectToCompare = (country, sector, sectors) => ({
  ...country,
  sector: {
    code: get(sector, "code", null),
    name: getSectorName(sector, sectors),
  },
});

function getSubCat(sc, arr, data) {
  return {
    name: sc.description,
    indicators: sc.indicators
      .filter(i => arr.findIndex(j => j === i.key) !== -1)
      .map(ind => ({
        name: ind.description,
        risk: data[ind.key],
      })),
  };
}

export function parseRisks(data, flowCodes) {
  if (data) {
    const riskKeys = Object.keys(data);
    const firstInd = flowCodes.filter(i => riskKeys.findIndex(c => c.slice(0, 1) === i.key) !== -1);
    const secondInd = firstInd.map(i => ({
      category: i.description,
      subcategories: i.subcategories.filter(
        j => riskKeys.findIndex(k => k.slice(0, 3) === j.key) !== -1
      ),
    }));
    return secondInd.map(i => ({
      category: i.category,
      subcategories: i.subcategories.map(j => getSubCat(j, riskKeys, data)),
    }));
  }
  return null;
}

// removes one or more countries from Redux store
export function removeCountries(removeAction, ISO_A3, countries, sectorName) {
  countries.forEach((country, index) => {
    if (country.ISO_A3 === ISO_A3 && country.sector.name === sectorName) {
      removeAction(index);
    }
  });
}

export function renderRisks(data) {
  if (!data) return <NoData />;
  return data.map(item => (
    <Item key={item.category}>
      <Category>{item.category}</Category>
      {item.subcategories.map((subcategory, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Collapse key={index} bordered={false}>
          <CollapsePanel
            header={<PanelHeader categoryName={subcategory.name} />}
            key={subcategory.name}
            showArrow={false}
          >
            {subcategory.indicators.length === 0 && <div>{STRINGS.NO_DATA}</div>}
            {subcategory.indicators.map(indicator => (
              <Tooltip
                placement="leftTop"
                title={indicator.name}
                key={indicator.name + indicator.risk}
              >
                <Indicator>
                  <RiskTitle>{indicator.name}</RiskTitle>
                  <RiskLevel>
                    <div
                      style={{
                        width: `${getRiskBarWidth(indicator.risk) * 2}%`,
                        background: getRiskColor(indicator.risk),
                      }}
                    />
                  </RiskLevel>
                </Indicator>
              </Tooltip>
            ))}
          </CollapsePanel>
        </Collapse>
      ))}
    </Item>
  ));
}

function PanelHeader(props) {
  const { categoryName, } = props;
  return (
    <Fragment>
      <div>{categoryName}</div>
      <ArrowDown />
    </Fragment>
  );
}
