import { PAGES_WITHOUT_NAVBAR, } from "./config";

export const leaveAnalyzePage = actions => {
  actions.forEach(action => action());
};

export const getActiveLink = path => {
  if (/trade/i.test(path) || /social-footprint/i.test(path)) return ["1",];
  if (/inventory/i.test(path)) return ["3",];
  if (/network/i.test(path)) return ["4",];

  return ["2",];
};

export const shouldHideNavbar = pathname => PAGES_WITHOUT_NAVBAR.some(page => page === pathname);

export const redirectToHomePage = (conditions, history) => {
  if (conditions) history.replace("/");
};
