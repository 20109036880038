export const defaultMenuList = [
  // {
  //   link: "/trade/country-specific-sectors",
  //   name: "Ethical Supply Chain",
  //   key: "1",
  // },
  {
    link: "http://www.socialhotspot.org/the-30-subcategories-in-the-shdb.html",
    name: "Documentation",
    key: "1",
  },
  {
    link: "/",
    name: "Explore Risk",
    key: "2",
  },
];

export default defaultMenuList;
