import styled from "styled-components";
import { Select as AntSelect, } from "antd";
import theme from "../../assets/theme";

export const Select = styled(AntSelect)`
  min-width: 220px;
  max-width: 400px;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  .ant-select-arrow {
    transform: translateY(-25%);
  }

  &.ant-select-open,
  &.ant-select-focused {
    .ant-select-selection {
      box-shadow: none;
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
  }
`;

export const Text = styled.span`
  line-height: 30px;
  font-size: 16px;
  color: ${theme.grey.dark};
  font-weight: 400;
`;
