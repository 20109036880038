import styled from "styled-components";
import { Input as AntInput, } from "antd";

export const Input = styled(AntInput)`
  width: 220px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  display: flex;
  align-items: center;

  &.ant-input {
    box-shadow: none;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
`;

export default Input;
