import styled from "styled-components";
import { Button, } from "antd";
import theme from "assets/theme";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  justify-content: space-between;
`;

export const CompareButton = styled(Button)`
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  box-shadow: 0 2px 4px 0 ${theme.shadow.default};

  &:after {
    display: none;
  }

  a {
    text-decoration: none;
  }

  span {
    position: relative;
  }

  &&.active,
  &&:active {
    color: ${theme.green.default};
    box-shadow: 0 2px 4px 0 ${theme.shadow.default};
    background-color: #fafafa;
  }

  &:focus {
    box-shadow: 0 0 0 3px ${theme.green.ocean};
  }

  &[disabled],
  &[disabled]:hover {
    background: white;
    color: ${theme.green.ocean};
  }
`;

export const Badge = styled.sup`
  position: absolute;
  top: -10px;
  right: -13px;
  display: inline-block;
  min-width: 16px;
  padding: 0 5px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  background-color: ${theme.green.dark};
  border-radius: 10px;
  color: white;
  font-size: 10px;
  font-weight: 700;
`;

export const FilterBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearFiltersButton = styled.div`
  &:before {
    content: 'Clear filters';
  }
  cursor: pointer;
  color: ${theme.green.default};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.3px;
`;
