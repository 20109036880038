import React, { Component, } from "react";
import { connect, } from "react-redux";
import { Helmet, } from "react-helmet";
import { isEmpty, get, } from "lodash";
import { Spin, } from "antd";

import topography from "assets/topography/world-110m.json";

import CountryInfoDrawer from "containers/CountryInfoDrawer";
import { setSectors, unsetSectors, } from "store/actions/sectors";
import { setFilter, } from "store/actions/filters";
import { setGeography, unsetGeography, } from "store/actions/geography";
import { setRisksField, } from "store/actions/risks";

import Filter from "views/pages/ExploreRisks/Filter";
import Map from "views/pages/ExploreRisks/Map";

import { FILTERS, REQUEST_TYPES, } from "constants";
import {
  getCountriesRisks,
  getGeoDataByRiskLevels,
  getRisks,
  removeSectorFromRisksKeys,
} from "utils/helpers";
import API from "utils/api";
import handleError from "utils/errorHandler";

import { Content, Header, SpinWrap, MapWrap, Skeleton, } from "./styled-components";

class ExploreRisk extends Component {
  async componentDidMount() {
    const {
      setSectors: _setSectors,
      setRisksField: _setRisksField,
      setGeography: _setGeography,
    } = this.props;
    try {
      // TODO: fetch only if no data exist in redux store
      const data = await API.getExploreRisks();
      const formattedSectors = Object.keys(data.sector_codes).map(key => ({
        key: key.toUpperCase(),
        description: data.sector_codes[key],
      }));

      _setSectors(formattedSectors);
      _setGeography(topography);
      _setRisksField(data.flow_codes, "flowCodes");
      _setRisksField(data.country_codes, "countryCodes");
      _setRisksField(data.data, "risks");
    } catch (error) {
      handleError(error);
    }
  }

  onCountrySelect = async country => {
    const {
      setFilter: _setFilter,
      filters: { sector, },
      setRisksField: _setRisksField,
    } = this.props;
    _setFilter(FILTERS.COUNTRY, country);
    _setRisksField(null, "currentCountryRisks");
    try {
      const data = await API.getCountryRisks(
        country.ISO_A3.toLowerCase(),
        get(sector, "code").toLowerCase()
      );
      _setRisksField(Object.values(data)[0], "currentCountryRisks");
    } catch (e) {
      handleError(e);
    }
  };

  render() {
    const { sectors, geography, risks, filters, sync, } = this.props;
    let formattedGeography;

    // Colorize map by risks level data
    // if sector & category choosen -> don't colorize map
    if (
      (get(filters, "sector.code") || get(filters, "category.code")) &&
      !get(filters, "subcategory.code") &&
      !get(filters, "indicator.code") &&
      risks.mapRisks
    ) {
      formattedGeography = getGeoDataByRiskLevels({
        data: geography,
        // country colors data
        rawRisks: {},
        // tooltips info
        risks: getRisks(risks.mapRisks, risks.flowCodes),
      });
      // if subcategory choosen -> colorize map
    } else if (
      (get(filters, "sector.code") || get(filters, "category.code")) &&
      !get(filters, "indicator.code") &&
      risks.mapRisks
    ) {
      formattedGeography = getGeoDataByRiskLevels({
        data: geography,
        rawRisks: removeSectorFromRisksKeys(risks.mapRisks),
        risks: getRisks(risks.mapRisks, risks.flowCodes),
      });
    } else {
      formattedGeography = getGeoDataByRiskLevels({
        data: geography,
        rawRisks: {},
        risks: getCountriesRisks(filters, risks.mapRisks),
        isIndicator: true,
      });
    }

    const isLoading =
      isEmpty(formattedGeography) ||
      get(sync, "activeRequest") === REQUEST_TYPES.EXPLORE_RISK_FILTERS;
    const isCountriesClickable = filters[FILTERS.SECTOR];
    return (
      <>
        <Helmet>
          <title>Social HotSpot - Explore Risks</title>
          <meta name="description" content="Helmet application" />
        </Helmet>
        <Header>
          {isEmpty(sectors) ? (
            <Skeleton active paragraph={{ rows: 0, }} title={{ rows: 1, }} />
          ) : (
            <Filter sectorList={sectors} risks={risks} onSelect={this.onSelectFilter} />
          )}
        </Header>
        <Content>
          {isLoading ? (
            <SpinWrap>
              <Spin />
            </SpinWrap>
          ) : (
            <MapWrap>
              <Map
                geographyData={formattedGeography}
                onCountrySelect={isCountriesClickable && this.onCountrySelect}
                withLegend
                withTooltip
                withHoveredEffects
              />
              <CountryInfoDrawer />
            </MapWrap>
          )}
        </Content>
      </>
    );
  }
}

const mapStateToProps = ({ sectors, geography, risks, filters, sync, }) => ({
  sectors,
  geography,
  risks,
  filters,
  sync,
});

export default connect(
  mapStateToProps,
  {
    setSectors,
    unsetSectors,
    setGeography,
    unsetGeography,
    setRisksField,
    setFilter,
  }
)(ExploreRisk);
