import call from "utils/apiConfig";
import { REQUEST_TYPES, REQUEST_METHODS, } from "utils/constants";

export default {
  login: data =>
    call({
      url: "users/sign-in",
      method: REQUEST_METHODS.POST,
      typeRequest: REQUEST_TYPES.LOGIN,
      data,
      withoutToken: true,
    }),
  addUser: data =>
    call({
      url: "users/sign-up",
      method: REQUEST_METHODS.POST,
      data,
    }),
  getExploreRisks: () =>
    call({
      url: "references",
      typeRequest: REQUEST_TYPES.COMPARE_RISKS,
    }),
  getMapRisks: (sector, category, subcategory) => {
    let url;
    if (subcategory) {
      url = `explore-risk/risks?sector="${sector}"&category="${category}"&subcategory="${subcategory}"`;
    } else if (category) {
      url = `explore-risk/risks?sector="${sector}"&category="${category}"`;
    } else {
      url = `explore-risk/risks?sector="${sector}"`;
    }
    return call({
      url,
      typeRequest: REQUEST_TYPES.EXPLORE_RISK_FILTERS,
    });
  },
  getCountryRisks: (country, sector) =>
    call({
      url: `explore-risk/risks?country="${country}"&sector="${sector}"`,
      typeRequest: REQUEST_TYPES.COMPARE_RISKS,
    }),
  getCompareRisks: params =>
    call({
      url: `explore-risk/compare?css=${params}`,
      typeRequest: REQUEST_TYPES.COMPARE_RISKS,
    }),
  getRisksHotspots: css =>
    call({
      url: `explore-risk/compare?css=${css}&type=hotspot`,
      typeRequest: REQUEST_TYPES.COMPARE_RISKS,
    }),
  getSocialHotspot: ({ type, value, css, demand, product, }) =>
    call({
      url: `analyze/social-hotspot?type=${type}&value=${value}&demand=${demand}${
        css ? `&css=${css}` : `&product=${product}`
      }`,
      typeRequest: REQUEST_TYPES.COMPARE_RISKS,
    }),
  getCountrySpecificSectors: param =>
    call({
      url: `trade/supply-chain?css=${param}`,
      typeRequest: REQUEST_TYPES.COUNTRY_SPECIFIC_SECTORS,
    }),
  getInventory: ({ category, subcategory, css, demand, product, }) =>
    call({
      url: `analyze/inventory?category=${category}&subcategory=${subcategory}&demand=${demand}${
        css ? `&css=${css}` : `&product=${product}`
      }`,
    }),
  getProducts: () => call({ url: "trade/products", typeRequest: REQUEST_TYPES.GET_PRODUCTS, }),
  getProduct: params =>
    call({
      url: `trade/products/${params}`,
      typeRequest: REQUEST_TYPES.GET_PRODUCT,
    }),
  removeProduct: params =>
    call({
      url: `trade/products/${params}`,
      method: REQUEST_METHODS.DELETE,
      typeRequest: REQUEST_TYPES.REMOVE_PRODUCT,
    }),
  createProduct: data =>
    call({
      url: "trade/products",
      method: REQUEST_METHODS.POST,
      typeRequest: REQUEST_TYPES.CREATE_PRODUCT,
      data,
    }),
  editProduct: (params, data) =>
    call({
      url: `trade/products/${params}`,
      method: REQUEST_METHODS.PUT,
      typeRequest: REQUEST_TYPES.EDIT_PRODUCT,
      data,
    }),
  getProductTemplate: param =>
    call({
      url: `trade/css/${param}`,
      typeRequest: REQUEST_TYPES.GET_PRODUCT_TEMPLATE,
    }),
  getNetwork: ({ css, demand, product, }) =>
    call({
      url: `analyze/network?&demand=${demand}${css ? `&css=${css}` : `&product=${product}`}`,
      typeRequest: REQUEST_TYPES.GET_NETWORK,
    }),
  getSocialFootprint: ({ css, amount, products, }) =>
    call({
      url: `trade/social-footprint?css=${css}&amount=${amount}&products=${products}`,
      typeRequest: REQUEST_TYPES.SOCIAL_FOOTPRINT,
    }),

  // Users
  getUsers: () => call({ url: "users", }),
  deleteUser: id =>
    call({
      url: `users/${id}`,
      method: REQUEST_METHODS.DELETE,
    }),
  updateUser: data =>
    call({
      url: `users/${data._id}`,
      method: REQUEST_METHODS.PUT,
      data,
    }),
};
