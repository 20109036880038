import {
  SET_FILTER,
  SELECT_COUNTRY,
  DESELECT_COUNTRY,
  RESET_FILTERS,
  RESET_COUNTRIES,
  SELECT_COMPARE_FILTER,
  DESELECT_COMPARE_FILTER,
  SET_COMPARE_AMOUNT,
} from "./types";

export const setFilter = (type, value) => ({
  type: SET_FILTER,
  payload: { type, value, },
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
  payload: {},
});

export const selectCountry = (country, index) => ({
  type: SELECT_COUNTRY,
  payload: { country, index, },
});

export const deselectCountry = index => ({
  type: DESELECT_COUNTRY,
  payload: { index, },
});

export const resetCountries = () => ({
  type: RESET_COUNTRIES,
});

export const selectCompareFilter = (key, value, type) => ({
  type: SELECT_COMPARE_FILTER,
  payload: { key, value, type, },
});

export const deselectCompareFilter = key => ({
  type: DESELECT_COMPARE_FILTER,
  payload: { key, },
});

export const setCompareAmount = value => ({
  type: SET_COMPARE_AMOUNT,
  payload: { value, },
});
