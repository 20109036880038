import React from "react";
import jwtDecode from "jwt-decode";
import { BrowserRouter as Router, } from "react-router-dom";
import { Provider, } from "react-redux";
import { Layout, } from "antd";
import "antd/dist/antd.less";

import Navbar from "views/layouts/Navbar";
import ErrorWrapper from "views/layouts/ErrorWrapper";
import { setCurrentUser, unsetCurrentUser, } from "store/actions/currentUser";

import store from "store";
import Navigation from "./navigation";

if (localStorage.user) {
  const { token, isTradeAvailable } = JSON.parse(localStorage.user); // eslint-disable-line
  const decodedUser = jwtDecode(token);
  store.dispatch(setCurrentUser({ isTradeAvailable, token: decodedUser, }));

  const currentTime = Date.now() / 1000,
    tokenTime = new Date(decodedUser.expiredAt),
    tokenTimeInSeconds = tokenTime.getTime() / 1000;

  if (tokenTimeInSeconds < currentTime) {
    localStorage.clear();
    store.dispatch(unsetCurrentUser());
  }
}

const App = () => (
  <Provider store={store}>
    <Router>
      <ErrorWrapper>
        <Layout style={{ minHeight: "100%", }}>
          <Navbar />
          <Navigation />
        </Layout>
      </ErrorWrapper>
    </Router>
  </Provider>
);

export default App;
