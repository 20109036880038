import styled from "styled-components";
import { Layout, Menu as AntMenu, } from "antd";
import theme from "assets/theme";

export const Logo = styled.img`
  display: block;
  width: 180px;
`;

export const Header = styled(Layout.Header)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 83px;
  box-shadow: ${theme.shadow.header};
  background: ${theme.white.pale};
  padding: 0 50px;
`;

export const Menu = styled(AntMenu)`
  text-align: right;
  border-bottom: none;

  .ant-menu-item {
    top: 0;
    padding: 0;
    margin-left: 40px;
    margin-top: 0;
    font-size: 16px;
    color: ${theme.grey.dark};
    opacity: 0.6;
    font-weight: 500;
    border-bottom: none;
    transition: opacity 0.3s;

    &:hover,
    &:active,
    &-active,
    &-selected {
      opacity: 1;
      border-bottom: none;
      color: inherit;
      > a {
        color: inherit;
      }
    }
  }
`;
