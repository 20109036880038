import styled from "styled-components";
import theme from "assets/theme";

export const Button = styled.div`
  text-align: right;
  border-bottom: none;
  margin-left: 40px;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s;
  cursor: pointer;

  &:hover,
  &:active,
  &-active,
  &-selected {
    color: ${theme.green.default};
  }
`;

export default Button;
