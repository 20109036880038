import { START_FETCHING, STOP_FETCHING, } from "./types";

export const startFetching = (data = {}) => ({
  type: START_FETCHING,
  meta: data.meta,
  payload: data,
});

export const stopFetching = (data = {}) => ({
  type: STOP_FETCHING,
  error: data.error,
});
