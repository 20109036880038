import API from "utils/api";
import handleError from "utils/errorHandler";
import { FILTERS, } from "constants";

export function parseCategories(flowCodes) {
  return flowCodes.map(category => ({ key: category.key, description: category.description, }));
}

export function parseSubcategories(flowCodes, categoryCode) {
  return flowCodes.find(category => category.key === categoryCode).subcategories;
}

export function parseIndicators(flowCodes, subcategoryCode) {
  return parseSubcategories(flowCodes, subcategoryCode.split(".")[0]).find(
    subcategory => subcategory.key === subcategoryCode
  ).indicators;
}

export async function fetchMapRisks({ sector, category, subcategory, }, setDataToStore) {
  try {
    if (sector) {
      const data = await API.getMapRisks(sector, category, subcategory);
      setDataToStore(data, "mapRisks");
    }
  } catch (error) {
    handleError(error);
  }
}

export function changeFilter(targetField, payload, setFn) {
  setFn(targetField, payload);
  resetOtherFilters(targetField, setFn);
}

// reset other filters if one of filters was changed
function resetOtherFilters(targetField, setFilterFn) {
  // sorted by nesting level
  const filters = [FILTERS.SUBCATEGORY, FILTERS.INDICATOR,];
  const removeFromIndex = filters.findIndex(field => field === targetField) + 1;
  filters.slice(removeFromIndex).forEach(field => setFilterFn(field, undefined));
}
