import React, { PureComponent, } from "react";
import { ComposableMap, ZoomableGroup, Geographies, Geography, Markers, } from "react-simple-maps";
import Tooltip from "react-tooltip";
import { isEmpty, } from "lodash";

import Legend from "components/Legend";
import TooltipCard from "./TooltipCard";

import { Wrapper, StyledTooltip, ZoomButtons, } from "./styled-components";
import { geographyStyles, } from "./styles";

const ZOOM_KOEFICIENT = 1.5;

export default class GeographyMap extends PureComponent {
  static defaultProps = {
    zoomable: false,
  };

  state = {
    zoom: 1,
  };

  zoomIn = () => this.setState(({ zoom, }) => ({ zoom: zoom * ZOOM_KOEFICIENT, }));

  zoomOut = () => this.setState(({ zoom, }) => ({ zoom: zoom / ZOOM_KOEFICIENT, }));

  render() {
    const {
      geographyData,
      withLegend,
      onCountrySelect,
      withTooltip,
      markers,
      withHoveredEffects,
      zoomable,
    } = this.props;
    const { zoom, } = this.state;

    if (isEmpty(geographyData)) return null;
    return (
      <Wrapper>
        {zoomable && (
          <ZoomButtons>
            <button type="button" onClick={this.zoomIn}>
              +
            </button>
            <button type="button" onClick={this.zoomOut}>
              &#8722;
            </button>
          </ZoomButtons>
        )}

        <ComposableMap
          projectionConfig={{
            scale: 200,
            xOffset: -15,
            rotation: [-10, 0, 0,],
          }}
          width={860}
          height={500}
        >
          <ZoomableGroup zoom={zoom} center={[0, 20,]} disablePanning={!zoomable}>
            {/* We pass "disableOptimization" to let Geographies to re-render on new props.
            In our case we need to re-calculate styles (re-fill country color by risk level) */}
            <Geographies disableOptimization geography={geographyData}>
              {(geographies, projection) =>
                geographies.map(
                  (geography, i) =>
                    geography.id !== "ATA" && (
                      <Geography
                        // for optimizing (help to cache the paths and significantly accelerate rerenders)
                        cacheId={geography.properties.ISO_A3}
                        // eslint-disable-next-line react/no-array-index-key
                        key={geography.properties.ISO_A3 + i}
                        data-tip={JSON.stringify({
                          country: geography.properties.NAME,
                          risks: geography.properties.risks,
                        })}
                        geography={geography}
                        projection={projection}
                        style={geographyStyles(geography, withHoveredEffects)}
                        onClick={value => onCountrySelect && onCountrySelect(value.properties)}
                      />
                    )
                )
              }
            </Geographies>

            <Markers>{markers}</Markers>
          </ZoomableGroup>
        </ComposableMap>
        {withLegend && <Legend />}

        {withTooltip && (
          <StyledTooltip>
            <Tooltip delayShow={200} getContent={dataTip => <TooltipCard data={dataTip} />} />
          </StyledTooltip>
        )}
      </Wrapper>
    );
  }
}
