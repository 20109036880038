import { Link, } from "react-router-dom";
import React from "react";
import { Menu, } from "./styled-components";
import { getActiveLink, } from "../helpers";
import { analyzeMenuList, } from "./config";

const AnalyzeMenu = ({ path, menuLinkDisabled, }) => (
  <Menu theme="light" mode="horizontal" defaultSelectedKeys={getActiveLink(path)}>
    {analyzeMenuList.map(menuItem => (
      <Menu.Item key={menuItem.key} disabled={menuItem.key !== "1" && menuLinkDisabled}>
        <Link to={menuItem.link}>{menuItem.name}</Link>
      </Menu.Item>
    ))}
  </Menu>
);

export default AnalyzeMenu;
