import { ADD_MATERIAL, SET_MATERIALS, REMOVE_MATERIALS, REMOVE_MATERIAL, } from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_MATERIAL: {
      const items = [...state,];
      return [...items, action.payload,];
    }
    case SET_MATERIALS: {
      return action.payload;
    }
    case REMOVE_MATERIAL: {
      const items = [...state,],
        index = items.findIndex(value => JSON.stringify(value) === action.payload);
      if (index !== -1) {
        items.splice(index, 1);
      }
      return items;
    }
    case REMOVE_MATERIALS: {
      return [];
    }
    default:
      return state;
  }
};
