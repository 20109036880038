import styled from "styled-components";
import { Collapse as AntCollapse, } from "antd";

import CloseIcon from "icons/Close";
import CompareIcon from "icons/Compare";
import theme from "assets/theme";
import { STRINGS, } from "constants";

export const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  background: ${theme.white.dark};
  width: 40%;
  max-width: 620px;
  overflow-y: auto;
`;

export const Overlay = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0
  right: 0;
`;

export const Header = styled.div`
  background: ${theme.green.default};
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 20px;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
`;

export const Title = styled.div`
  font-weight: 500;
  margin-right: 50px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Icons = styled.div`
  align-items: center;
  display: flex;
  color: #fff;
`;

export const Divider = styled.div`
  width: 1px;
  margin: 0 15px;
  height: 29px;
  background: #fff;
  opacity: 0.22;
`;

export const CountryInfo = styled.div`
  display: flex;
  padding: 24px 0;
`;

export const CountryName = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${theme.green.dark};
  margin-bottom: 10px;
`;

export const Additional = styled.div`
  display: flex;
  color: ${theme.grey.default};
  font-weight: 500;
  line-height: 1.36;
  > div {
    margin-right: 24px;
  }
  strong {
    opacity: 0.6;
    font-weight: 500;
    padding-right: 6px;
  }
`;

export const Line = styled.div`
  background: ${theme.line};
  height: 1px;
  margin: 0 auto;
`;

export const Content = styled.div`
  padding: 0 20px 10px;
`;

export const Item = styled.div`
  margin-top: 20px;
`;

export const Category = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: ${theme.grey.dark};
  margin-top: 4px
  margin-bottom: 13px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin-left: 24px;
`;

export const RiskTitle = styled.div`
  align-self: flex-start;
  width: 100%;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RiskLevel = styled.div`
  width: 100%;
  max-width: 212px;
  > div {
    height: 100%;
  }
`;

export const Flag = styled.div`
  width: 127px;
  height: 72px;
  box-shadow: 0 2px 4px 0 rgba(0, 28, 40, 0.2);
  span {
    width: 100%;
    height: 100%;
    object-width: cover;
  }
`;

export const Indicator = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: ${theme.green.dark};
  margin-bottom: 9px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const IconCompare = styled(CompareIcon)`
  cursor: pointer;
  opacity: ${props => (props.active === "true" ? "1" : "0.7")};
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const IconClose = styled(CloseIcon)`
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const Collapse = styled(AntCollapse)`
  position: relative;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 28, 40, 0.1);
  > .ant-collapse-item {
    :last-child {
      border-bottom: none;
    }
  }
`;

export const CollapsePanel = styled(AntCollapse.Panel)`
  position: relative;
  margin: 0;
  &.ant-collapse-item-active {
    && > .ant-collapse-header {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  && > .ant-collapse-header {
    position: relative;
    z-index: 1;
    padding: 14px 12px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: ${theme.green.ocean};
    svg {
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: all 0.3s;
    }
  }
  .ant-collapse-content {
    padding: 12px;
    box-shadow: 0 -1px 1px -1px rgba(0, 28, 40, 0.2);
    color: inherit;
    .ant-collapse-content-box {
      padding: 0;
      margin-top: -4px;
    }
  }
`;

export const SpinWrapper = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoData = styled.div`
  &:after {
    content: "${STRINGS.NO_DATA}";
  }
  margin: 5% auto;
`;
