import { Layout, Skeleton as AntSkeleton, } from "antd";
import styled from "styled-components";
import theme from "../../assets/theme";

export const Header = styled(Layout.Header)`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 14px 50px;
  background: ${theme.white.default};
  min-height: 64px;
  line-height: normal;
`;

export const Content = styled(Layout.Content)`
  display: flex;
  position: relative;
  background: #fff;
`;

export const Skeleton = styled(AntSkeleton)`
  .ant-skeleton-title {
    margin: 0;
    + .ant-skeleton-paragraph {
      margin: 0;
    }
  }
`;

export const SpinWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MapWrap = styled.div`
  width: 100%;
  max-height: calc(100vh - 147px);
  overflow: hidden;
  position: relative;
`;
