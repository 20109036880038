// Core
import React, { lazy, } from "react";
import { Route, Switch, } from "react-router-dom";
import { Layout, } from "antd";

// Utils
import { waitingComponent, PrivateRoute, GuestRoute, TradeRoute, } from "utils/helpers";

// Paths
import paths from "./paths";
import AuthorizationPage from "../containers/AuthorizationPage";
import ExploreRisk from "../containers/ExploreRisks";
import ComparePage from "../containers/ComparePage";

// Chunks
const CountrySpecificSectors = lazy(() => import("containers/Trade/CountrySpecificSectors"));
const MyProducts = lazy(() => import("containers/Trade/MyProducts"));
const Compare = lazy(() => import("containers/Trade/Compare"));
const Inventory = lazy(() => import("containers/AnalyzeRisks/Inventory"));
const Network = lazy(() => import("containers/AnalyzeRisks/Network"));
const SocialHotspot = lazy(() => import("containers/AnalyzeRisks/SocialHotspot"));
const SocialFootprint = lazy(() => import("containers/AnalyzeRisks/SocialFootprint"));
const CreateNewProduct = lazy(() => import("containers/Trade/MyProducts/CreateNewProduct"));
const AdminUsers = lazy(() => import("containers/AdminPage/Users"));

export default () => (
  <Layout>
    <GuestRoute path={paths.login} component={AuthorizationPage} />
    <PrivateRoute exact path="/" component={ExploreRisk} />
    <PrivateRoute path={paths.compare} component={ComparePage} />
    <Route
      pathname={paths.trade}
      render={() => (
        <Switch>
          <TradeRoute
            path={paths.countrySpecificSectors}
            component={waitingComponent(CountrySpecificSectors)}
          />
          <TradeRoute path={paths.myProducts} component={waitingComponent(MyProducts)} />
          <TradeRoute
            path={paths.createNewProduct}
            component={waitingComponent(CreateNewProduct)}
          />
          <TradeRoute path={paths.tradeCompare} component={waitingComponent(Compare)} />
        </Switch>
      )}
    />
    <Route pathname={paths.analyze}>
      <Switch>
        <PrivateRoute path={paths.inventory} component={waitingComponent(Inventory)} />
        <PrivateRoute path={paths.network} component={waitingComponent(Network)} />
        <PrivateRoute path={paths.socialHotspot} component={waitingComponent(SocialHotspot)} />
        <PrivateRoute path={paths.socialFootprint} component={waitingComponent(SocialFootprint)} />
      </Switch>
    </Route>
    <Route pathname={paths.admin}>
      <Switch>
        <Route path={paths.users} component={waitingComponent(AdminUsers)} />
      </Switch>
    </Route>
  </Layout>
);
