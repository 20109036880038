import { Modal, } from "antd";

export default function handleError(error) {
  // No Internet
  if (error.message === "Network Error") {
    showErrorModal("Error", "There is a problem with the Internet connection.");
  }

  // User is unauthorized to perform an action
  if (/401/.test(error.message)) {
    showErrorModal(
      "Error",
      "It seems that your subscription has expired. Please contact catherine@newearthb.com"
    );
  }

  return console.error(error);
}

function showErrorModal(title, content) {
  return Modal.error({ title, content, });
}
