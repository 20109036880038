import React from "react";
import { connect, } from "react-redux";
import { Link, } from "react-router-dom";

import { withRouter, } from "react-router";
import { STRINGS, } from "utils/constants";
import { removeComparison, } from "store/actions/isComparison";
import logoImg from "./assets/logo.svg";
import { leaveAnalyzePage, shouldHideNavbar, redirectToHomePage, } from "./helpers";
import AnalyzeMenu from "./AnalyzeMenu";
import DefaultMenu from "./DefaultMenu";
import LogoutButton from "./LogoutButton";
import { Header, Logo, LeftItemLink, LinkBack, MenuBlock, } from "./styled-components";

const Navbar = ({
  isComparison,
  isAnalyze,
  history,
  currentUser,
  removeComparison: _removeComparison,
}) => {
  const { pathname, } = history.location,
    isAnalyzeLocation = pathname.indexOf(STRINGS.ANALYZE) !== -1,
    correctAnalyzeLink = (isComparison && isAnalyzeLocation) || (isAnalyze && isAnalyzeLocation);

  redirectToHomePage(!correctAnalyzeLink && isAnalyzeLocation, history);

  if (shouldHideNavbar(pathname)) return null;

  return (
    <Header>
      {correctAnalyzeLink ? (
        <LeftItemLink to={localStorage.getItem("link")}>
          <LinkBack onClick={() => leaveAnalyzePage([_removeComparison,])}>
            &#8592; Back to Ethical Supply Chain
          </LinkBack>
        </LeftItemLink>
      ) : (
        <Link to="/">
          <Logo src={logoImg} alt="SHDB" />
        </Link>
      )}

      {currentUser && (
        <MenuBlock>
          {correctAnalyzeLink ? (
            <AnalyzeMenu path={pathname} menuLinkDisabled={isComparison} />
          ) : (
            <DefaultMenu path={pathname} menuLinkDisabled={!currentUser.isTradeAvailable} />
          )}
          <LogoutButton />
        </MenuBlock>
      )}
    </Header>
  );
};

const mapStateToProps = ({ isComparison, analyze, currentUser, }) => ({
  isComparison,
  isAnalyze: analyze.isAnalyzePage,
  currentUser,
});

export default withRouter(
  connect(
    mapStateToProps,
    { removeComparison, }
  )(Navbar)
);
