export default {
  white: {
    default: "#f7fafa",
    dark: "#f8fbfc",
    light: "#e1e7e7",
    pale: "#ffffff",
    background: "#fafbfc",
    modalContent: "#fafcfd",
  },
  green: {
    default: "#3ab890",
    ocean: "#2e9686",
    light: "#95cb70",
    medium: "#73a451",
    dark: "#415552",
    pale: "#e8f7f2",
  },
  grey: {
    default: "#616161",
    light: "#afc2c2",
    medium: "#838c88",
    dark: "#515151",
  },
  red: {
    default: "#7f2a34",
    light: "#e95365",
    pale: "#c23748",
    dark: "#51161D",
  },
  yellow: {
    default: "#f7c675",
    dark: "#e6b35f",
  },
  map: {
    default: "#e1e7e7",
    stroke: "#d9d9d9",
    fill: "#FF5722",
    noEvidence: "#78cde8",
    noData: "#e1e7e7",
  },
  line: "#cfd8dc",
  extraLine: "#e1e7e7",
  legend: "#37474f",
  border: "#dbdbdb",
  cancelButton: "#ffffff",
  shadow: {
    default: "rgba(0, 28, 40, 0.1)",
    content: "0 0 4px 0 rgba(0, 42, 29, 0.1)",
    header: "0 2px 4px 0 rgba(0, 42, 29, 0.1)",
  },
};
