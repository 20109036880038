export const passwordIsCorrect = password =>
  password.length > 3 ? "" : "Password must have at least 4 characters";

export const isEmail = login => {
  // eslint-disable-next-line no-useless-escape
  const email = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/, "igm");
  return email.test(login) ? "" : "Email is incorrect";
};

export const isLoginValue = fieldName => fieldName === "login";
