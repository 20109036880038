import React from "react";

const SvgCompare = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <path
        id="prefix__a2"
        d="M21.358 11.491h-.042L18.993 7.54h1.221a.636.636 0 0 0 .642-.63v-.897a.636.636 0 0 0-.642-.631h-7.572V3.631A.636.636 0 0 0 12 3a.636.636 0 0 0-.642.63v1.752H3.786a.636.636 0 0 0-.642.63v.897c0 .349.287.63.642.63h1.22l-2.322 3.952h-.042a.636.636 0 0 0-.642.63c0 2.14 1.773 3.882 3.952 3.882 2.178 0 3.951-1.741 3.951-3.881a.636.636 0 0 0-.642-.63H9.22L6.896 7.54h4.462v11.574H9.261c-.81 0-1.47.647-1.47 1.443S8.452 22 9.262 22h5.478c.81 0 1.47-.647 1.47-1.443s-.66-1.443-1.47-1.443h-2.097V7.54h4.462l-2.325 3.95h-.042a.636.636 0 0 0-.642.63c0 2.14 1.773 3.882 3.952 3.882 2.178 0 3.951-1.741 3.951-3.881a.636.636 0 0 0-.642-.63zM4 11l2-3 2 3H4zm14-3l2 3h-4l2-3z"
      />
      <path id="prefix__c2" d="M0 0h50v50H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b2" fill="#fff">
        <use xlinkHref="#prefix__a2" />
      </mask>
      <use fill="#3ab890" fillRule="nonzero" xlinkHref="#prefix__a2" />
      <g mask="url(#prefix__b2)">
        <use fill="currentColor" transform="translate(-13 -13)" xlinkHref="#prefix__c2" />
      </g>
    </g>
  </svg>
);

export default SvgCompare;
