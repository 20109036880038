import { ADD_COMPARISON, REMOVE_COMPARISON, } from "../actions/types";

export default (state = false, action) => {
  switch (action.type) {
    case ADD_COMPARISON:
      return true;
    case REMOVE_COMPARISON:
      return false;
    default:
      return state;
  }
};
