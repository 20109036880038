import React, { lazy, Suspense, } from "react";
import Loading from "components/Loading";
import { Helmet, } from "react-helmet";
import { COMPARE_PAGE_TABS, } from "utils/constants";
import { Main, Wrapper, Header, Menu, Content, } from "./styled-components";

const CountriesListSidebar = lazy(() => import("./CountriesList"));
const SocialHotspotIndex = lazy(() => import("./SocialHotspotIndex"));
const MultiplyBarCharts = lazy(() => import("./MultiplyBarCharts"));

export default class ComparePage extends React.Component {
  state = {
    currentTab: COMPARE_PAGE_TABS.MULTIPLY_BAR_CHARTS,
  };

  render() {
    const { currentTab, } = this.state;
    return (
      <>
        <Helmet>
          <title>Social HotSpot - Compare</title>
          <meta name="description" content="Helmet application" />
        </Helmet>
        <Header>
          <Menu theme="light" mode="horizontal" defaultSelectedKeys={["1",]}>
            <Menu.Item
              key="1"
              onClick={() => this.setState({ currentTab: COMPARE_PAGE_TABS.MULTIPLY_BAR_CHARTS, })}
            >
              Multiple issue bar chart
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => this.setState({ currentTab: COMPARE_PAGE_TABS.SOCIAL_HOTSPOT_INDEX, })}
            >
              Social Hotspot Index
            </Menu.Item>
          </Menu>
        </Header>

        <Main>
          <Wrapper>
            <Suspense fallback={<Loading onlySpinner />}>
              <CountriesListSidebar tab={currentTab} />
            </Suspense>

            <Suspense fallback={<Loading onlySpinner />}>
              <Content>
                {currentTab === COMPARE_PAGE_TABS.MULTIPLY_BAR_CHARTS ? (
                  <MultiplyBarCharts />
                ) : (
                  <SocialHotspotIndex />
                )}
              </Content>
            </Suspense>
          </Wrapper>
        </Main>
      </>
    );
  }
}
