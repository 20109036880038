import styled from "styled-components";
import theme from "../../assets/theme";

export const Wrapper = styled.div`
  background: ${theme.white.default};
  position: fixed;
  bottom: 50px;
  left: 50px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 2px 7px 18px -4px rgba(156, 156, 156, 1);
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const ColoredBox = styled.div`
  height: 14px;
  width: 14px;
  margin-right: 7px;
`;

export const Value = styled.div`
  font-size: 12px;
  letter-spacing: -0.2px;
  color: ${theme.legend};
  line-height: 14px;
`;
