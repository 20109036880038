// Sectors List
export const SET_SECTORS = "SET_SECTORS";
export const UNSET_SECTORS = "SET_SECTORS";

// Geography
export const SET_GEOGRAPHY = "SET_GEOGRAPHY";
export const UNSET_GEOGRAPHY = "SET_GEOGRAPHY";

// Risks
export const SET_RISKS_FIELD = "SET_RISKS_FIELD";
export const SET_COMPARE_RISKS = "SET_COMPARE_RISKS";
export const SET_RISKS_HOTSPOTS = "SET_RISKS_HOTSPOTS";
export const SET_EXPLORE_RISKS = "SET_EXPLORE_RISKS";
export const REMOVE_RISKS = "REMOVE_RISKS";

// Filters
export const SET_FILTER = "SET_FILTER";
export const RESET_FILTERS = "RESET_FILTERS";
export const SELECT_COUNTRY = "SELECT_COUNTRY";
export const DESELECT_COUNTRY = "DESELECT_COUNTRY";
export const RESET_COUNTRIES = "RESET_COUNTRIES";
export const SELECT_COMPARE_FILTER = "SELECT_COMPARE_FILTER";
export const DESELECT_COMPARE_FILTER = "DESELECT_COMPARE_FILTER";
export const SET_COMPARE_AMOUNT = "SET_COMPARE_AMOUNT";

// Request
export const SUCCESS_REQUEST = "SUCCESS_REQUEST";
export const ERROR_REQUEST = "ERROR_REQUEST";
export const REQUEST = "REQUEST";
export const START_FETCHING = "START_FETCHING";
export const STOP_FETCHING = "STOP_FETCHING";

// Materials
export const ADD_MATERIAL = "ADD_MATERIAL";
export const SET_MATERIALS = "SET_MATERIALS";
export const REMOVE_MATERIALS = "REMOVE_MATERIALS";
export const REMOVE_MATERIAL = "REMOVE_MATERIAL";

// Editable product
export const SET_EDITABLE_PRODUCT = "SET_EDITABLE_PRODUCT";
export const REMOVE_EDITABLE_PRODUCT = "REMOVE_EDITABLE_PRODUCT";

// Product template
export const SET_PRODUCT_TEMPLATE = "SET_PRODUCT_TEMPLATE";
export const REMOVE_PRODUCT_TEMPLATE = "REMOVE_PRODUCT_TEMPLATE";

// Products list
export const SET_PRODUCT = "SET_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";

// Comparison
export const ADD_COMPARISON = "ADD_COMPARISON";
export const REMOVE_COMPARISON = "REMOVE_COMPARISON";

// Analyze
export const SET_ANALYZE_FIELD = "SET_ANALYZE_FIELD";

// user
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UNSET_CURRENT_USER = "UNSET_CURRENT_USER";

// fetching
export const SET_LOADING = "SET_LOADING";
export const UNSET_LOADING = "UNSET_LOADING";
