import React from "react";

const SvgClose = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <path
        id="prefix__a3"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
      <path id="prefix__c3" d="M0 0h50v50H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b3" fill="#fff">
        <use xlinkHref="#prefix__a3" />
      </mask>
      <g mask="url(#prefix__b3)">
        <use fill="currentColor" transform="translate(-13 -13)" xlinkHref="#prefix__c3" />
      </g>
    </g>
  </svg>
);

export default SvgClose;
