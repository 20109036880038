import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Country = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.3px;
  margin-right: 10px;
`;

export const RiskLevel = styled.div`
  font-weight: 300;
  letter-spacing: -0.2px;

  span {
    font-weight: 400;
  }
`;

export const Content = styled.div`
  margin-top: 10px;
  letter-spacing: -0.2px;
  strong {
    font-weight: 600;
  }
`;

export const Risks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`;

export const Text = styled(RiskLevel)`
  width: 100%;
`;
