import styled from "styled-components";

import theme from "assets/theme";

export const Wrapper = styled.div`
  position: relative;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledTooltip = styled.div`
  .__react_component_tooltip.place-top.type-dark {
    padding: 15px 20px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1.67;
    box-shadow: 0 2px 4px 0 rgba(0, 28, 40, 0.1);
    background: rgba(1, 21, 18, 0.7);
    opacity: 1;
    &:after {
      border-top-color: rgba(1, 21, 18, 0.7);
    }
  }
`;

export const ZoomButtons = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;

  button {
    background: white;
    width: 35px;
    height: 35px;
    padding: 0px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    color: ${theme.green.default};
    font-size: 20px;
    font-weight: 600;
    border: 0.5px solid ${theme.white.light};
    box-shadow: 0px 0px 6px -3px rgba(84, 84, 84, 1);
    cursor: pointer;
    border-radius: 2px;
  }
`;
