import { REMOVE_EDITABLE_PRODUCT, SET_EDITABLE_PRODUCT, } from "../actions/types";

export default (state = null, action) => {
  switch (action.type) {
    case SET_EDITABLE_PRODUCT: {
      return action.payload;
    }
    case REMOVE_EDITABLE_PRODUCT: {
      return null;
    }
    default:
      return state;
  }
};
