import React, { Component, } from "react";
import { withRouter, } from "react-router";
import { Helmet, } from "react-helmet";
import { connect, } from "react-redux";
import { get, } from "lodash";
import jwtDecode from "jwt-decode";
import { setCurrentUser, } from "store/actions/currentUser";
import Button from "components/Button";
import Input from "components/Input";
import API from "utils/api";
import { REQUEST_TYPES, } from "constants";
import Loading from "components/Loading";

import { passwordIsCorrect, isEmail, isLoginValue, } from "./helpers";
import { Content, Container, Form, Title, FieldBlock, ErrorMessage, } from "./styled-components";
import { buttonStyles, loadingStyles, } from "./styles";

class AuthorizationPage extends Component {
  state = {
    login: {},
    password: {},
  };

  signIn = async () => {
    const {
        login: { value: login, },
        password: { value: password, },
      } = this.state,
      { history, } = this.props;

    try {
      const data = await API.login({ login, password, });
      await this.setUser(data);
      history.push("/");
    } catch (error) {
      this.toggleRequestError(get(error, "response.data.message", ""));
    }
  };

  setUser = data => {
    const { setCurrentUser: _setCurrentUser, } = this.props,
      { token, is_trade_available } = data, // eslint-disable-line
      decodedUser = jwtDecode(token),
      userData = { token: decodedUser, isTradeAvailable: is_trade_available, };

    localStorage.setItem("user", JSON.stringify({ isTradeAvailable: is_trade_available, token, }));
    _setCurrentUser(userData);
  };

  onChange = (fieldName, value) => {
    let error;

    if (isLoginValue(fieldName)) {
      error = isEmail(value);
    } else {
      error = passwordIsCorrect(value);
    }
    this.setState({ [fieldName]: { value, error, }, });
  };

  valuesAreCorrect = values =>
    !values.every(value => get(value, "value", "") && !get(value, "error", ""));

  toggleDisplayError = errorType => {
    this.setState({ [errorType]: true, });
  };

  toggleRequestError = errorMessage => {
    this.setState({
      errorMessage,
    });
  };

  render() {
    const { login, password, loginError, passwordError, errorMessage, } = this.state,
      { activeRequest, } = this.props,
      disabledButton = this.valuesAreCorrect([login, password,]),
      isLoading = activeRequest === REQUEST_TYPES.LOGIN;
    return (
      <>
        <Helmet>
          <title>Social HotSpot - Compare</title>
          <meta name="description" content="Helmet application" />
        </Helmet>
        <Content>
          {isLoading ? (
            <Loading customStyles={loadingStyles} />
          ) : (
            <Container>
              <Title>Login</Title>
              <Form>
                <FieldBlock>
                  <Input
                    value={get(login, "value", "")}
                    onBlur={() => this.toggleDisplayError("loginError")}
                    placeholder="Login"
                    onChange={value => this.onChange("login", value)}
                  />
                  <ErrorMessage visible={loginError}>{get(login, "error", "")}</ErrorMessage>
                </FieldBlock>
                <FieldBlock>
                  <Input
                    value={get(password, "value", "")}
                    onBlur={() => this.toggleDisplayError("passwordError")}
                    placeholder="Password"
                    type="password"
                    onChange={value => this.onChange("password", value)}
                  />
                  <ErrorMessage visible={passwordError}>{get(password, "error", "")}</ErrorMessage>
                </FieldBlock>
                <Button
                  disabled={disabledButton}
                  onClick={() => this.signIn()}
                  style={buttonStyles}
                >
                  Sign in
                </Button>
                <ErrorMessage main visible={errorMessage}>
                  {errorMessage}
                </ErrorMessage>
              </Form>
            </Container>
          )}
        </Content>
      </>
    );
  }
}

const mapStateToProps = ({ sync, }) => ({
  activeRequest: get(sync, "activeRequest"),
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      setCurrentUser,
    }
  )(AuthorizationPage)
);
