import React from "react";

import { RISKS, } from "constants";
import { Wrapper, Item, ColoredBox, Value, } from "./styled-components";

export default () => (
  <Wrapper>
    {Object.keys(RISKS)
      .reverse()
      .map(key => (
        <Item key={RISKS[key].NAME}>
          <ColoredBox style={{ background: RISKS[key].COLOR, }} />
          <Value>{RISKS[key].NAME}</Value>
        </Item>
      ))}
  </Wrapper>
);
