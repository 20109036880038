import styled from "styled-components";
import { Link as RouterLink, } from "react-router-dom";
import theme from "assets/theme";
import { Layout, } from "antd";

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  background: ${theme.white.modalContent};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  text-align: center;
  margin: 3% 0;
`;

export const Link = styled(RouterLink)`
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  width: 100%;
  color: ${theme.green.dark};
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 10px;
`;

export const Header = styled(Layout.Header)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 83px;
  box-shadow: ${theme.shadow.header};
  background: ${theme.white.pale};
  padding: 0 50px;
`;

export const FieldBlock = styled.div`
  position: relative;
  width: 220px;
  margin: 0 auto 30px;
`;

export const ErrorMessage = styled.div`
${props =>
  props.main
    ? ""
    : `
position: absolute;
  top: 40px;`}
opacity: ${props => (props.visible ? "1" : "0")};
transition: opacity 0.3s;
color: ${theme.red.pale};
`;
