export const analyzeMenuList = [
  {
    link: "/analyze/social-footprint",
    name: "Social footprint",
    key: "1",
  },
  {
    link: "/analyze/social-hotspot",
    name: "Social Hotspot",
    key: "2",
  },
  {
    link: "/analyze/inventory",
    name: "Inventory",
    key: "3",
  },
  {
    link: "/analyze/Network",
    name: "Network",
    key: "4",
  },
];

export default analyzeMenuList;
