import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${props => props.height};
  background: white;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0.7;
`;

export const SpinWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
