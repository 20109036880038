import React from "react";
import { FILTERS, STRINGS, } from "constants";
import { Text, } from "./styled-components";

export function getIndicators(risks) {
  const MAX_RISKS = 5;
  if (!risks) {
    return null;
  }
  if (!Object.keys(risks).length) {
    return <Text>{STRINGS.NO_DATA}</Text>;
  }
  if (risks && Object.keys(risks).length) {
    return Object.keys(risks)
      .filter(key => key !== "undefined")
      .map(key => (
        <Text key={key}>
          {key}: <span>{risks[key]}</span>
        </Text>
      ))
      .slice(0, MAX_RISKS);
  }
  return null;
}

export function getHintText(filters) {
  if (!filters[FILTERS.SECTOR]) {
    return "Please select a sector.";
  }
  return false;
}
