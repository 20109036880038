import React from "react";
import { connect, } from "react-redux";

import { getIndicators, getHintText, } from "./helpers";
import { Wrapper, Content, Header, Country, RiskLevel, Risks, } from "./styled-components";

const Tooltip = ({ data, filters, }) => {
  if (!data) return null;
  const formatted = JSON.parse(data);
  const shouldShowIndicators = filters.sector;

  return (
    <Wrapper>
      <Header>
        <Country>{formatted.country}</Country>
        {filters.indicator && (
          <RiskLevel>
            Risk level: <span>{formatted.risks.primary}</span>
          </RiskLevel>
        )}
      </Header>

      {!filters.indicator && (
        <Content>
          <Risks>{shouldShowIndicators && getIndicators(formatted.risks.other)}</Risks>
          {getHintText(filters)}
        </Content>
      )}
    </Wrapper>
  );
};

const mapStateToProps = ({ filters, }) => ({
  filters,
});

export default connect(
  mapStateToProps,
  null
)(Tooltip);
