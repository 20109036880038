import { SET_GEOGRAPHY, UNSET_GEOGRAPHY, } from "./types";

export const setGeography = geography => ({
  type: SET_GEOGRAPHY,
  payload: geography,
});

export const unsetGeography = () => ({
  type: UNSET_GEOGRAPHY,
  payload: {},
});
