import React, { Component, } from "react";
import { withRouter, } from "react-router";
import { connect, } from "react-redux";
import { unsetCurrentUser, } from "store/actions/currentUser";
import { Button, } from "./styled-components";

class LogoutButton extends Component {
  logout = () => {
    const { unsetCurrentUser: _unsetCurrentUser, history, } = this.props;
    _unsetCurrentUser();
    localStorage.removeItem("token");
    history.push("/");
  };

  render() {
    return <Button onClick={this.logout}>Log out</Button>;
  }
}

export default withRouter(
  connect(
    () => ({}),
    {
      unsetCurrentUser,
    }
  )(LogoutButton)
);
