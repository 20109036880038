import styled from "styled-components";
import { Layout, Menu as AntMenu, } from "antd";
import theme from "assets/theme";

export const Main = styled.div`
  position: relative;
  height: calc(100vh - 136px);
  overflow: hidden;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 47%;
    width: 1px;
    height: 100%;
    content '';
    background: ${theme.white.light};
    
     @media (min-width: 1289px) {
    left: 615px;
  }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 0;
  background: ${theme.white.background};
`;

export const Header = styled(Layout.Header)`
  background: #fff;
  border-bottom: 1px solid ${theme.white.light};
  height: auto;
  line-height: normal;
`;

export const Menu = styled(AntMenu)`
  width: 100%;
  text-align: center;
  line-height: 50px;
  border-bottom: none;
  
  .ant-menu-item {
    margin: 0 20px;
    font-size: 14px;
    border-bottom: 2px solid transparent;
    top: 0;
    padding: 0;
    font-weight: 500;
    opacity: 0.6;
    transition: opacity 0.3s;
    
    &:active,
    &-active,
    &:hover {
      opacity: 1;
      color: inherit;
      border-color: transparent;
    }
    
    &-selected,
    &-selected:hover{
      opacity: 1;
      border-color: ${theme.green.default};
      color: inherit;
      
      > a {
        color: inherit;
      }
    };
`;

export const Content = styled(Layout.Content)`
  width: 53%;
  flex-grow: 1;
`;
