import React, { Component, } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import { Tooltip, } from "antd";

import CountryFlag from "react-country-flag";
import {
  Table as TableWrapper,
  Column,
  Header,
  Row,
  SortChar,
  Flag,
  Item,
} from "./styled-components";
import countryFlagStyles from "./styles";
import { ColoredCircle, } from "../TableManager/styled-components";

export const SORT_AS = {
  STRING: "String",
  NUMBER: "Number",
};

const sortingFunction = {
  [SORT_AS.STRING]: (a, b, key) => {
    if (a[key] < b[key]) {
      return 1;
    }
    if (a[key] > b[key]) {
      return -1;
    }

    return 0;
  },
  [SORT_AS.NUMBER]: (a, b, key) => numeral(b[key]).value() - numeral(a[key]).value(),
};

export default class Table extends Component {
  constructor(props) {
    super(props);
    const { data, } = props;
    this.state = {
      sortKey: null,
      data,
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => ({
    data: nextProps.data,
    sortKey: prevState.sortKey,
  });

  sortBy = (key, sortAs) => {
    const { sortKey, data, } = this.state;
    const { sortable, } = this.props;

    if (!sortable) return;

    data.items =
      sortKey === key
        ? // sort
          data.items.sort((a, b) => sortingFunction[sortAs](a, b, key))
        : // reverse sort
          data.items.sort((a, b) => sortingFunction[sortAs](b, a, key));
    this.setState({
      data,
      sortKey: sortKey === key ? `-${key}` : key,
    });
  };

  renderSortingChar = (sortKey, key) => {
    if (key === sortKey) {
      return "▲";
    }

    if (sortKey === `-${key}`) {
      return "▼";
    }
    return null;
  };

  render() {
    const { sortKey, data, } = this.state;
    const { sortable, withoutBorder, scroll, margin, forGraphs, } = this.props;
    return (
      <TableWrapper scroll={scroll}>
        {data.headers.map(header => (
          <Tooltip title={header.tooltipTitle} key={header.name + Math.random()}>
            <Column
              withoutBorder={withoutBorder}
              key={header.name}
              fitContent={header.fitContent}
              width={header.width}
              margin={margin}
              forGraphs={forGraphs}
            >
              <Header onClick={() => this.sortBy(header.key, header.sortAs)}>
                <div>{header.name}</div>
                {sortable ? (
                  <SortChar>{this.renderSortingChar(sortKey, header.key)}</SortChar>
                ) : null}
              </Header>
              {/* We pass random key here as "row" is non-unique string, it can be repeated */}
              {data.items.map(item => (
                <Row key={Math.random()} height={item.height} forGraphs={forGraphs}>
                  {header.key === "country" && item.flag ? (
                    <Flag>
                      <CountryFlag code={item.flag} svg styleProps={countryFlagStyles} />
                    </Flag>
                  ) : null}
                  <Tooltip title={item[header.key]}>
                    <div style={{ display: "flex", alignItems: "center", }}>
                      {header.key === "sector" && (
                        <ColoredCircle width="15px" height="15px" color={item.color} />
                      )}
                      <Item>{item[header.key]}</Item>
                    </div>
                  </Tooltip>
                </Row>
              ))}
            </Column>
          </Tooltip>
        ))}
      </TableWrapper>
    );
  }
}

Table.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        tooltipTitle: PropTypes.string,
        key: PropTypes.string.isRequired,
        sortAs: PropTypes.oneOf([SORT_AS.STRING, SORT_AS.NUMBER,]),
        fitContent: PropTypes.bool,
        width: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  sortable: PropTypes.bool,
  scroll: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  margin: PropTypes.string,
  forGraphs: PropTypes.bool,
};

Table.defaultProps = {
  sortable: false,
  scroll: false,
  withoutBorder: false,
  forGraphs: false,
  margin: "0.5%",
};
