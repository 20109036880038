import styled from "styled-components";
import theme from "assets/theme";

export const Table = styled.div`
  display: flex;
  width: 100%;
  ${props => (props.scroll ? "overflow-x: scroll; max-height: 200px" : "")};
`;

export const Header = styled.div`
  background: ${theme.green.pale};
  padding: 11px;
  color: ${theme.green.ocean};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const Row = styled.div`
  margin: 15px;
  display: flex;
  align-items: center;
  font-size: 16px;
  height: ${props => props.height || "15px"};
  > div {
    ${props => (props.forGraphs ? "width: 100%;" : "unset")};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Column = styled.div`
  margin: ${props => props.margin || "0.5%"};
  ${props => (props.withoutBorder ? "" : `border: 1px solid ${theme.border}`)};
  background: white;
  min-width: ${props => props.width};
  ${props => (props.fitContent ? "flex-basis: fit-content;" : "flex-grow: 1")};
  &:first-child {
    ${props => (props.forGraphs ? "width: 70%;" : "auto")};
  }
  &:last-child {
    ${props => (props.forGraphs ? "width: 30%;" : "auto")};
  }
`;

export const SortChar = styled.div`
  width: 1ch;
`;

export const Flag = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 17px;
  align-self: flex-end;
  margin-right: 10px;
`;

export const Item = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
