import React from "react";

const ArrowDown = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <path id="prefix__a" d="M7 10l5 5 5-5z" />
      <path id="prefix__c" d="M0 0h50v50H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <g mask="url(#prefix__b)">
        <use fill="rgba(52,68,66,0.6)" transform="translate(-13 -13)" xlinkHref="#prefix__c" />
      </g>
    </g>
  </svg>
);

export default ArrowDown;
