import React from "react";
import { connect, } from "react-redux";
import CountryFlag from "react-country-flag";
import { Spin, } from "antd";
import { get, } from "lodash";
import numeral from "numeral";

import { setFilter, selectCountry, deselectCountry, } from "store/actions/filters";
import { FILTERS, } from "constants";
import {
  getSectorName,
  isCountryComparing,
  parseRisks,
  removeCountries,
  composeCountryObjectToCompare,
  renderRisks,
} from "./helpers";
import {
  Wrapper,
  Header,
  Title,
  Icons,
  CountryInfo,
  Info,
  CountryName,
  Additional,
  Line,
  Content,
  Flag,
  Divider,
  IconClose,
  IconCompare,
  Overlay,
  SpinWrapper,
} from "./styled-components";

const CountryInfoDrawer = ({
  filters: { country, sector, countries, },
  sectors,
  setFilter: _setFilter,
  selectCountry: _selectCountry,
  deselectCountry: _deselectCountry,
  risks: { currentCountryRisks, flowCodes, },
  sync,
}) => {
  if (!country) return null;
  const sectorName = getSectorName(sector, sectors);
  const isComparing = isCountryComparing(country, countries, sectorName);
  const population = numeral(get(country, "POP_EST", 0)).format("0,0");
  const parsedCountryRisks = parseRisks(currentCountryRisks, flowCodes);
  return (
    <div>
      <Overlay onClick={() => _setFilter(FILTERS.COUNTRY, null)} />
      <Wrapper>
        <Header>
          <Title>
            {country.NAME} - {sectorName}
          </Title>
          <Icons>
            <IconCompare
              active={isComparing.toString()}
              onClick={() =>
                isComparing
                  ? removeCountries(_deselectCountry, country.ISO_A3, countries, sectorName)
                  : _selectCountry(
                      composeCountryObjectToCompare(country, sector, sectors),
                      countries.length
                    )
              }
            />
            <Divider />
            <IconClose onClick={() => _setFilter(FILTERS.COUNTRY, null)} />
          </Icons>
        </Header>
        <Content>
          <CountryInfo>
            <Flag>
              <CountryFlag
                code={country.ISO_A2}
                svg
                styleProps={{
                  width: "100%",
                  height: "100%",
                  backgroundSize: "cover",
                }}
              />
            </Flag>
            <Info>
              <CountryName>{country.NAME}</CountryName>
              <Additional>
                <div>
                  <strong>Population:</strong> {population}
                </div>
              </Additional>
            </Info>
          </CountryInfo>
          <Line />
          {sync ? (
            <SpinWrapper>
              <Spin />
            </SpinWrapper>
          ) : (
            renderRisks(parsedCountryRisks)
          )}
        </Content>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = ({ sectors, filters, risks, sync, }) => ({
  sectors,
  filters,
  risks,
  sync: get(sync, "isFetching"),
});

export default connect(
  mapStateToProps,
  {
    setFilter,
    selectCountry,
    deselectCountry,
  }
)(CountryInfoDrawer);
