import styled from "styled-components";
import theme from "assets/theme";

export const TableWrapper = styled.div`
  width: 40%;
  overflow: hidden;
  margin: 2% 0;
  flex-basis: 40%;
  border: 1px solid ${theme.white.light};
  ${props => props.margin && "margin: 2% 1%;"}
  background: white;
`;

export const ColoredCircle = styled.div`
  min-height: 15px;
  min-width: 15px;
  border-radius: 30px;
  background: ${props => props.color};
  margin-right: 5px;
`;
