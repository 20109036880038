import { SET_CURRENT_USER, UNSET_CURRENT_USER, } from "./types";

export const setCurrentUser = data => ({
  type: SET_CURRENT_USER,
  payload: data,
});

export const unsetCurrentUser = () => ({
  type: UNSET_CURRENT_USER,
});
