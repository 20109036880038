import React from "react";
import PropTypes from "prop-types";
import { Tooltip, } from "antd";

import ArrowDown from "assets/icons/ArrowDown";
import sortItems from "./sort";
import { Select, Text, } from "./styled-components";

export default props => {
  const { optionList, defaultValue, sorted, } = props;
  const options = sorted ? optionList.sort((a, b) => sortItems(a, b, "description")) : optionList;
  return (
    <Select
      key={defaultValue}
      size="large"
      suffixIcon={<ArrowDown />}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.option.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
    >
      {options &&
        options.map(item => {
          const bold = item.key.endsWith(".z");

          return (
            <Select.Option key={item.key} value={item.key} option={item.description}>
              {props.withTooltip ? (
                <Tooltip placement="leftTop" title={item.description}>
                  <Text style={{ fontWeight: bold ? "bold" : "normal", }}>{item.description}</Text>
                </Tooltip>
              ) : (
                <Text style={{ fontWeight: bold ? "bold" : "normal", }}>{item.description}</Text>
              )}
            </Select.Option>
          );
        })}
    </Select>
  );
};

Select.propTypes = {
  optionList: PropTypes.arrayOf(PropTypes.object),
  withTooltip: PropTypes.bool,
  sorted: PropTypes.bool,
};

Select.defaultProps = {
  sorted: false,
};
