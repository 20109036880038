import { SET_SECTORS, UNSET_SECTORS, } from "./types";

export const setSectors = sectors => ({
  type: SET_SECTORS,
  payload: sectors,
});

export const unsetSectors = () => ({
  type: UNSET_SECTORS,
  payload: [],
});
