import styled from "styled-components";
import { Layout, } from "antd";
import theme from "assets/theme";
import { Link, } from "react-router-dom";

export const Logo = styled.img`
  display: block;
  width: 180px;
`;

export const Header = styled(Layout.Header)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 83px;
  box-shadow: ${theme.shadow.header};
  background: ${theme.white.pale};
  padding: 0 50px;
`;

export const LeftItemLink = styled(Link)`
  color: ${theme.green.dark};
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  &:hover {
    color: ${theme.green.dark};
    opacity: 0.9;
  }
`;

export const LinkBack = styled.div``;

export const MenuBlock = styled.div`
  display: flex;
  align-items: center;
`;
