import React, { Suspense, } from "react";
import { Route, Redirect, } from "react-router-dom";
import _, { get, mapKeys, } from "lodash";

import { SORT_AS, } from "components/Table";
import Loading from "components/Loading";
import { MAP, RISKS, } from "constants";
import store from "store";

// colored risk level bars takes 0.5 of full block width as an another width takes text
const MAX_RISK_LEVEL_BAR_WIDTH = 0.5;

export const getRiskColor = riskValue => get(RISKS[riskValue], "COLOR", MAP.DEFAULT_COUNTRY_COLOR);
export const getRiskName = riskValue => get(RISKS[riskValue], "NAME", RISKS.ND.NAME);

export const getRiskBarWidth = riskCode =>
  get(RISKS[riskCode], "BAR_WIDTH", 0) * MAX_RISK_LEVEL_BAR_WIDTH;

export const getSectorNameByCode = (code, sectors, fallback) => {
  const sectorObject = Object.values(sectors).find(sector => sector.key === code);
  return get(sectorObject, "description", fallback);
};

export function getCountriesRisks(filters, data) {
  if (!data || !get(filters, "indicator.code")) return {};
  const risks = {};
  mapKeys(data, (value, cssKey) => {
    const countryCode = cssKey.split("_").pop();
    risks[countryCode] = data[cssKey][filters.indicator.code];
  });
  return risks;
}

export function getRisks(data, flowCodes) {
  const risks = {};
  for (let i = 0; i < Object.keys(data).length; i += 1) {
    const cssKey = Object.keys(data)[i];
    const countryCode = cssKey.split("_").pop();
    risks[countryCode] = { ...data[cssKey], };
  }
  Object.keys(risks).forEach(countryCode => {
    Object.keys(risks[countryCode]).forEach(indicator => {
      risks[countryCode][getIndicatorName(indicator, flowCodes)] = getRiskName(
        risks[countryCode][indicator]
      );

      delete risks[countryCode][indicator];
    });
  });

  return risks;
}

export function removeSectorFromRisksKeys(data) {
  const risks = {};
  for (let i = 0; i < Object.keys(data).length; i += 1) {
    const cssKey = Object.keys(data)[i];
    const countryCode = cssKey.split("_").pop();
    risks[countryCode] = { ...data[cssKey], };
  }
  return risks;
}

function getIndicatorName(key, flowCodes) {
  const [category, subcategory, indicator,] = key.split(".").map(k => k.toLowerCase());
  if (category && !subcategory) {
    return get(flowCodes.find(c => c.key.toLowerCase() === category), "description", []);
  }
  if (category && subcategory && !indicator) {
    const subs = get(flowCodes.find(c => c.key.toLowerCase() === category), "subcategories", []);
    return get(subs.find(i => i.key.toLowerCase() === `${category}.${subcategory}`), "description");
  }
  if (category && subcategory && indicator) {
    const inds = get(
      flowCodes
        .find(c => c.key === category)
        .subcategories.find(sub => sub.key.toLowerCase() === `${category}.${subcategory}`),
      "indicators",
      []
    );
    return get(
      inds.find(i => i.key.toLowerCase() === `${category}.${subcategory}.${indicator}`),
      "description"
    );
  }
  return null;
}

export function getCategoryNameByCode(code, flowCodes) {
  return flowCodes.find(item => item.key === code).description;
}

export function getSubcategoryNameByCode(code, flowCodes) {
  const [categoryCode,] = code.split(".");
  const subcategories = get(flowCodes.find(item => item.key === categoryCode), "subcategories", []);
  return get(subcategories.find(s => s.key === code), "description", "Unknown");
}

const OVERALL_INDICATORS = ["1.E.c", "1.G.c", "1.I.n", "3.A.a", "3.B.e", "3.C.e", "3.D.q", "4.B.e",];

// returns edited geo objects: with changed colors
export function getGeoDataByRiskLevels({
  data = {},
  rawRisks = {},
  risks = {},
  isIndicator = false,
}) {
  const targetKey = "ne_110m_admin_0_countries";
  if (!get(data, `objects.${targetKey}`, null)) return null;

  return {
    ...data,
    objects: {
      ...data.objects,
      [targetKey]: {
        ...data.objects[targetKey],
        // map each country
        geometries: data.objects[targetKey].geometries.map(geoObject => {
          const countryName = geoObject.properties.ISO_A3.toLowerCase();
          let medianRiskInWord;
          if (rawRisks[countryName]) {
            // for some subcategories, we have an indicator that characterizes the overall risk level
            // we are using that if we find from constant list
            const overallIndicator = _.intersection(
              Object.keys(rawRisks[countryName]),
              OVERALL_INDICATORS
            );
            if (overallIndicator.length > 0) {
              medianRiskInWord = rawRisks[countryName][overallIndicator[0]];
            } else {
              // Sum all risks and divide to total amount of them
              const riskValues = Object.values(rawRisks[countryName]).map(parseRiskToValue);
              const sum = riskValues.length ? riskValues.reduce((a, b) => a + b) : 0;
              const risksCount = Object.values(rawRisks[countryName]).filter(removeND).length;
              const medianRiskValue = sum / risksCount;
              medianRiskInWord = parseValueToRisk(medianRiskValue);
            }
          }

          return {
            ...geoObject,
            properties: {
              ...geoObject.properties,
              color: isIndicator
                ? getRiskColor(risks[countryName])
                : getRiskColor(medianRiskInWord),
              risks: {
                // a risk level, when indicator choosen
                primary: getRiskName(risks[countryName]),
                // a risk levels, when sector, category, subcategory choosen
                other: risks[countryName],
              },
            },
          };
        }),
      },
    },
  };
}

function removeND(risk) {
  return risk !== "ND";
}

function parseRiskToValue(string) {
  const VALUES = {
    ND: 0,
    NE: 0,
    LR: 0.1,
    MR: 1,
    HR: 5,
    VH: 10,
  };

  return VALUES[string];
}

function parseValueToRisk(value) {
  if (!value && value !== 0) {
    return "ND";
  }
  if (Math.round(parseFloat(value) * 100) / 100 <= 0) {
    return "NE";
  }
  if (Math.round(parseFloat(value) * 100) / 100 <= 0.1) {
    return "LR";
  }
  if (Math.round(parseFloat(value) * 100) / 100 <= 1) {
    return "MR";
  }
  if (Math.round(parseFloat(value) * 100) / 100 <= 5) {
    return "HR";
  }

  return "VH";
}

export function colorizeChoosenCountries(data = {}, countries, color = "#6D7372") {
  const targetKey = "ne_110m_admin_0_countries";
  if (!get(data, `objects.${targetKey}`, null)) return null;

  return {
    ...data,
    objects: {
      ...data.objects,
      [targetKey]: {
        ...data.objects[targetKey],
        geometries: data.objects[targetKey].geometries.map(geoObject => {
          const isTarget = countries.some(code => code === geoObject.properties.ISO_A2);
          return {
            ...geoObject,
            properties: {
              ...geoObject.properties,
              color: isTarget && color,
            },
          };
        }),
      },
    },
  };
}

export const getAllCountries = (geoObjects, isTrade = false) =>
  isTrade
    ? // eslint-disable-next-line no-use-before-define
      countriesTrade
    : get(geoObjects, "ne_110m_admin_0_countries.geometries", []).map(
        geometry => geometry.properties
      );

export const convertCountriesListForSelect = countries =>
  countries.map(i => ({
    key: JSON.stringify(i),
    name: i.NAME,
    longName: i.NAME_LONG,
    description: i.NAME,
  }));

// get countries list for select from topography object
export const getCountriesForSelectFromGeography = geographyObject =>
  convertCountriesListForSelect(getAllCountries(geographyObject));

export const convertSectorsListForSelect = sectors =>
  sectors.map(sector => ({
    key: JSON.stringify(sector),
    description: sector.description,
  }));

// Component Lazy Loading to be used in react-router
export const waitingComponent = Component => props => (
  <Suspense fallback={<Loading />}>
    <Component {...props} />
  </Suspense>
);

// Private route
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser, } = store.getState();

  return (
    <Route
      {...rest}
      render={props => (currentUser ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};

// Trade route
export const TradeRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => <Component {...props} />} />
);

// Guest route
export const GuestRoute = ({ component: Component, ...rest }) => {
  const { currentUser, } = store.getState();

  return (
    <Route
      {...rest}
      render={props => (currentUser ? <Redirect to="/" /> : <Component {...props} />)}
    />
  );
};

export const chunkArray = (arr, chunkSize) => {
  const result = [];

  while (arr.length) {
    result.push(arr.splice(0, chunkSize));
  }

  return result;
};

export const splitCssCode = cssCode => {
  const keysArray = cssCode.split("_"),
    countryCode = keysArray.pop(),
    sectorCode = keysArray.join("_");

  return { countryCode, sectorCode, };
};

// return array of objects with keys: amount, sector, country
export const getSeparatedValues = ({ data, sectors, countries, }) => {
  const values = [];
  let country, sector, amount;
  mapKeys(data, (value, key) => {
    const { countryCode, sectorCode, } = splitCssCode(key);
    country = countries.find(c => c.ISO_A3.toLowerCase() === countryCode);
    sector = sectors.find(s => s.key === sectorCode.toUpperCase());
    amount = Math.round(value * 1000) / 1000;

    if (country && sector && amount) {
      values.push({
        country,
        sector,
        amount,
      });
    }
  });

  return values;
};

// table consists of columns 'sector', 'country', 'amount'
export const convertDataToTableFormat = ({ data, sectors, countries, currency, price, }) => {
  const values = getSeparatedValues({ data, sectors, countries, });
  const items = values.map(value => {
    const { sector, country, amount, } = value;

    return {
      country: get(country, "NAME", null),
      sector: get(sector, "description", null),
      flag: get(country, "ISO_A2", null),
      amount: `${currency}${amount / price}`,
    };
  });

  return {
    headers: [
      { name: "Sector", key: "sector", sortAs: SORT_AS.STRING, },
      { name: "Country", key: "country", sortAs: SORT_AS.STRING, },
      { name: "Amount", key: "amount", sortAs: SORT_AS.NUMBER, },
    ],
    items,
  };
};

export function getRiskKeyDescription(key, flowCodes) {
  const [categoryKey, subcategoryKey, indicatorKey,] = key.split(".").map(k => k.toLowerCase());
  const category = flowCodes.find(item => item.key === categoryKey);
  const subcategory = category.subcategories.find(
    sub => sub.key.toLowerCase() === `${categoryKey}.${subcategoryKey}`
  );
  const indicator = subcategory.indicators.find(
    ind => ind.key.toLowerCase() === `${categoryKey}.${subcategoryKey}.${indicatorKey}`
  );

  return [
    get(category, "description"),
    get(subcategory, "description"),
    get(indicator, "description"),
  ];
}

export const setOnlyNumbers = value => value.replace(/[^\d.]/g, "");

export const returnValueWithCurrencyIcon = (value, icon) => `${icon} ${value}`;

export const returnCorrectPriceValue = value => parseFloat(value);

const countriesTrade = [
  {
    NAME: "Australia",
    ISO_A3: "aus",
  },
  {
    NAME: "Samoa",
    ISO_A3: "wsm",
  },
  {
    NAME: "China",
    ISO_A3: "chn",
  },
  {
    NAME: "Hong Kong, Special Administrative Region of China",
    ISO_A3: "hkg",
  },
  {
    NAME: "Japan",
    ISO_A3: "jpn",
  },
  {
    NAME: "South Korea",
    ISO_A3: "kor",
  },
  {
    NAME: "Mongolia",
    ISO_A3: "mng",
  },
  {
    NAME: "Taiwan",
    ISO_A3: "twn",
  },
  {
    NAME: "Macao, Special Administrative Region of China",
    ISO_A3: "mac",
  },
  {
    NAME: "Brunei Darussalam",
    ISO_A3: "brn",
  },
  {
    NAME: "Cambodia",
    ISO_A3: "khm",
  },
  {
    NAME: "Indonesia",
    ISO_A3: "idn",
  },
  {
    NAME: "Laos",
    ISO_A3: "lao",
  },
  {
    NAME: "Malaysia",
    ISO_A3: "mys",
  },
  {
    NAME: "Philippines",
    ISO_A3: "phl",
  },
  {
    NAME: "Singapore",
    ISO_A3: "sgp",
  },
  {
    NAME: "Thailand",
    ISO_A3: "tha",
  },
  {
    NAME: "Vietnam",
    ISO_A3: "vnm",
  },
  {
    NAME: "Myanmar",
    ISO_A3: "mmr",
  },
  {
    NAME: "Bangladesh",
    ISO_A3: "bgd",
  },
  {
    NAME: "India",
    ISO_A3: "ind",
  },
  {
    NAME: "Nepal",
    ISO_A3: "npl",
  },
  {
    NAME: "Pakistan",
    ISO_A3: "pak",
  },
  {
    NAME: "Sri Lanka",
    ISO_A3: "lka",
  },
  {
    NAME: "Afghanistan",
    ISO_A3: "afg",
  },
  {
    NAME: "Canada",
    ISO_A3: "can",
  },
  {
    NAME: "United States of America",
    ISO_A3: "usa",
  },
  {
    NAME: "Mexico",
    ISO_A3: "mex",
  },
  {
    NAME: "Bermuda",
    ISO_A3: "bmu",
  },
  {
    NAME: "Argentina",
    ISO_A3: "arg",
  },
  {
    NAME: "Bolivia",
    ISO_A3: "bol",
  },
  {
    NAME: "Brazil",
    ISO_A3: "bra",
  },
  {
    NAME: "Chile",
    ISO_A3: "chl",
  },
  {
    NAME: "Colombia",
    ISO_A3: "col",
  },
  {
    NAME: "Ecuador",
    ISO_A3: "ecu",
  },
  {
    NAME: "Paraguay",
    ISO_A3: "pry",
  },
  {
    NAME: "Peru",
    ISO_A3: "per",
  },
  {
    NAME: "Uruguay",
    ISO_A3: "ury",
  },
  {
    NAME: "Venezuela (Bolivarian Republic of)",
    ISO_A3: "ven",
  },
  {
    NAME: "French Guiana",
    ISO_A3: "guf",
  },
  {
    NAME: "Costa Rica",
    ISO_A3: "cri",
  },
  {
    NAME: "Guatemala",
    ISO_A3: "gtm",
  },
  {
    NAME: "Honduras",
    ISO_A3: "hnd",
  },
  {
    NAME: "Nicaragua",
    ISO_A3: "nic",
  },
  {
    NAME: "Panama",
    ISO_A3: "pan",
  },
  {
    NAME: "El Salvador",
    ISO_A3: "slv",
  },
  {
    NAME: "Belize",
    ISO_A3: "blz",
  },
  {
    NAME: "Dominican Republic",
    ISO_A3: "dom",
  },
  {
    NAME: "Jamaica",
    ISO_A3: "jam",
  },
  {
    NAME: "Puerto Rico",
    ISO_A3: "pri",
  },
  {
    NAME: "Trinidad and Tobago",
    ISO_A3: "tto",
  },
  {
    NAME: "Cuba",
    ISO_A3: "cub",
  },
  {
    NAME: "Austria",
    ISO_A3: "aut",
  },
  {
    NAME: "Belgium",
    ISO_A3: "bel",
  },
  {
    NAME: "Cyprus",
    ISO_A3: "cyp",
  },
  {
    NAME: "Czech Republic",
    ISO_A3: "cze",
  },
  {
    NAME: "Denmark",
    ISO_A3: "dnk",
  },
  {
    NAME: "Estonia",
    ISO_A3: "est",
  },
  {
    NAME: "Finland",
    ISO_A3: "fin",
  },
  {
    NAME: "France",
    ISO_A3: "fra",
  },
  {
    NAME: "Germany",
    ISO_A3: "deu",
  },
  {
    NAME: "Greece",
    ISO_A3: "grc",
  },
  {
    NAME: "Hungary",
    ISO_A3: "hun",
  },
  {
    NAME: "Ireland",
    ISO_A3: "irl",
  },
  {
    NAME: "Italy",
    ISO_A3: "ita",
  },
  {
    NAME: "Latvia",
    ISO_A3: "lva",
  },
  {
    NAME: "Lithuania",
    ISO_A3: "ltu",
  },
  {
    NAME: "Luxembourg",
    ISO_A3: "lux",
  },
  {
    NAME: "Malta",
    ISO_A3: "mlt",
  },
  {
    NAME: "Netherlands",
    ISO_A3: "nld",
  },
  {
    NAME: "Poland",
    ISO_A3: "pol",
  },
  {
    NAME: "Portugal",
    ISO_A3: "prt",
  },
  {
    NAME: "Slovakia",
    ISO_A3: "svk",
  },
  {
    NAME: "Slovenia",
    ISO_A3: "svn",
  },
  {
    NAME: "Spain",
    ISO_A3: "esp",
  },
  {
    NAME: "Sweden",
    ISO_A3: "swe",
  },
  {
    NAME: "United Kingdom",
    ISO_A3: "gbr",
  },
  {
    NAME: "Switzerland",
    ISO_A3: "che",
  },
  {
    NAME: "Norway",
    ISO_A3: "nor",
  },
  {
    NAME: "Iceland",
    ISO_A3: "isl",
  },
  {
    NAME: "Liechtenstein",
    ISO_A3: "lie",
  },
  {
    NAME: "Albania",
    ISO_A3: "alb",
  },
  {
    NAME: "Bulgaria",
    ISO_A3: "bgr",
  },
  {
    NAME: "Belarus",
    ISO_A3: "blr",
  },
  {
    NAME: "Croatia",
    ISO_A3: "hrv",
  },
  {
    NAME: "Romania",
    ISO_A3: "rou",
  },
  {
    NAME: "Russian Federation",
    ISO_A3: "rus",
  },
  {
    NAME: "Ukraine",
    ISO_A3: "ukr",
  },
  {
    NAME: "Moldova",
    ISO_A3: "mda",
  },
  {
    NAME: "Serbia",
    ISO_A3: "srb",
  },
  {
    NAME: "Kazakhstan",
    ISO_A3: "kaz",
  },
  {
    NAME: "Kyrgyzstan",
    ISO_A3: "kgz",
  },
  {
    NAME: "Tajikistan",
    ISO_A3: "tjk",
  },
  {
    NAME: "Armenia",
    ISO_A3: "arm",
  },
  {
    NAME: "Azerbaijan",
    ISO_A3: "aze",
  },
  {
    NAME: "Georgia",
    ISO_A3: "geo",
  },
  {
    NAME: "Bahrain",
    ISO_A3: "bhr",
  },
  {
    NAME: "Iran",
    ISO_A3: "irn",
  },
  {
    NAME: "Israel",
    ISO_A3: "isr",
  },
  {
    NAME: "Jordan",
    ISO_A3: "jor",
  },
  {
    NAME: "Kuwait",
    ISO_A3: "kwt",
  },
  {
    NAME: "Oman",
    ISO_A3: "omn",
  },
  {
    NAME: "Qatar",
    ISO_A3: "qat",
  },
  {
    NAME: "Saudi Arabia",
    ISO_A3: "sau",
  },
  {
    NAME: "Turkey",
    ISO_A3: "tur",
  },
  {
    NAME: "United Arab Emirates",
    ISO_A3: "are",
  },
  {
    NAME: "Iraq",
    ISO_A3: "irq",
  },
  {
    NAME: "Egypt",
    ISO_A3: "egy",
  },
  {
    NAME: "Morocco",
    ISO_A3: "mar",
  },
  {
    NAME: "Tunisia",
    ISO_A3: "tun",
  },
  {
    NAME: "Algeria",
    ISO_A3: "dza",
  },
  {
    NAME: "Benin",
    ISO_A3: "ben",
  },
  {
    NAME: "Burkina Faso",
    ISO_A3: "bfa",
  },
  {
    NAME: "Cameroon",
    ISO_A3: "cmr",
  },
  {
    NAME: "Cote dIvoire",
    ISO_A3: "civ",
  },
  {
    NAME: "Ghana",
    ISO_A3: "gha",
  },
  {
    NAME: "Guinea",
    ISO_A3: "gin",
  },
  {
    NAME: "Nigeria",
    ISO_A3: "nga",
  },
  {
    NAME: "Senegal",
    ISO_A3: "sen",
  },
  {
    NAME: "Togo",
    ISO_A3: "tgo",
  },
  {
    NAME: "Mali",
    ISO_A3: "mli",
  },
  {
    NAME: "Congo",
    ISO_A3: "cog",
  },
  {
    NAME: "Angola",
    ISO_A3: "ago",
  },
  {
    NAME: "Ethiopia",
    ISO_A3: "eth",
  },
  {
    NAME: "Kenya",
    ISO_A3: "ken",
  },
  {
    NAME: "Madagascar",
    ISO_A3: "mdg",
  },
  {
    NAME: "Malawi",
    ISO_A3: "mwi",
  },
  {
    NAME: "Mauritius",
    ISO_A3: "mus",
  },
  {
    NAME: "Mozambique",
    ISO_A3: "moz",
  },
  {
    NAME: "Rwanda",
    ISO_A3: "rwa",
  },
  {
    NAME: "Tanzania",
    ISO_A3: "tza",
  },
  {
    NAME: "Uganda",
    ISO_A3: "uga",
  },
  {
    NAME: "Zambia",
    ISO_A3: "zmb",
  },
  {
    NAME: "Zimbabwe",
    ISO_A3: "zwe",
  },
  {
    NAME: "Seychelles",
    ISO_A3: "syc",
  },
  {
    NAME: "Botswana",
    ISO_A3: "bwa",
  },
  {
    NAME: "Namibia",
    ISO_A3: "nam",
  },
  {
    NAME: "South Africa",
    ISO_A3: "zaf",
  },
  {
    NAME: "Swaziland",
    ISO_A3: "swz",
  },
  {
    NAME: "French Southern Territories",
    ISO_A3: "atf",
  },
];
