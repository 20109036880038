import React from "react";
import { connect, } from "react-redux";
import { Link, } from "react-router-dom";
import { get, } from "lodash";

import Select from "components/Select";

import { setFilter, resetFilters, } from "store/actions/filters";
import { setRisksField, } from "store/actions/risks";
import { FILTERS, } from "constants";
import {
  parseCategories,
  parseSubcategories,
  parseIndicators,
  fetchMapRisks,
  changeFilter,
} from "./helpers";
import {
  Wrapper,
  CompareButton,
  Badge,
  FilterBlock,
  ClearFiltersButton,
} from "./styled-components";

const Filter = ({
  sectors,
  filters: { countries, sector, category, subcategory, indicator, },
  setFilter: _setFilter,
  risks: { flowCodes, },
  resetFilters: _resetFilters,
  setRisksField: _setRisksField,
}) => (
  <Wrapper>
    <FilterBlock>
      <Select
        showSearch
        sorted
        placeholder="All sectors"
        onChange={async value => {
          changeFilter(FILTERS.SECTOR, { code: value, }, _setFilter);
          await fetchMapRisks(
            {
              sector: value,
              category: category && get(category, "code"),
            },
            _setRisksField
          );
        }}
        value={get(sector, "code", undefined)}
        optionList={sectors && Object.values(sectors)}
      />
      <Select
        withTooltip
        showSearch
        placeholder="All categories"
        onChange={async value => {
          changeFilter(FILTERS.CATEGORY, { code: value, }, _setFilter);
          await fetchMapRisks(
            {
              sector: get(sector, "code"),
              category: value,
            },
            _setRisksField
          );
        }}
        value={get(category, "code", undefined)}
        optionList={flowCodes && parseCategories(flowCodes)}
      />

      {get(sector, "code") && get(category, "code") && (
        <Select
          withTooltip
          showSearch
          placeholder="Subcategory"
          onChange={async value => {
            changeFilter(FILTERS.SUBCATEGORY, { code: value, }, _setFilter);
            changeFilter(FILTERS.INDICATOR, { code: `${value}.z`, }, _setFilter);
            await fetchMapRisks(
              {
                sector: sector.code,
                category: category.code,
                subcategory: value.charAt(value.length - 1),
              },
              _setRisksField
            );
          }}
          value={get(subcategory, "code", undefined)}
          optionList={flowCodes && parseSubcategories(flowCodes, category.code)}
        />
      )}

      {get(subcategory, "code") && (
        <Select
          withTooltip
          showSearch
          placeholder="Indicator"
          onChange={value => changeFilter(FILTERS.INDICATOR, { code: value, }, _setFilter)}
          value={get(indicator, "code", undefined)}
          optionList={flowCodes && parseIndicators(flowCodes, subcategory.code)}
        />
      )}

      {(get(sector, "code") || get(category, "code")) && (
        <ClearFiltersButton onClick={_resetFilters} />
      )}
    </FilterBlock>

    <Link to="/compare">
      <CompareButton>
        <span>
          Compare<Badge>{countries.length}</Badge>
        </span>
      </CompareButton>
    </Link>
  </Wrapper>
);

const mapStateToProps = ({ sectors, filters, risks, }) => ({
  sectors,
  filters,
  risks,
});

export default connect(
  mapStateToProps,
  { setFilter, resetFilters, setRisksField, }
)(Filter);
