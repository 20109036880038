import theme from "assets/theme";

export const geographyStyles = (geography, withHoveredEffects) => ({
  default: {
    fill: geography.properties.color || theme.map.default,
    stroke: theme.map.stroke,
    strokeWidth: 0.75,
    outline: "none",
  },
  hover: {
    fill: withHoveredEffects ? theme.grey.dark : geography.properties.color || theme.map.default,
    stroke: theme.map.stroke,
    outline: "none",
    cursor: "pointer",
    opacity: 0.8,
  },
  pressed: {
    fill: geography.properties.color || theme.map.default,
    opacity: 0.8,
    stroke: theme.map.stroke,
    strokeWidth: 0.75,
    outline: "none",
  },
});

export default geographyStyles;
