import { RISK_TYPES, } from "utils/constants";
import {
  SET_RISKS_FIELD,
  SET_COMPARE_RISKS,
  SET_RISKS_HOTSPOTS,
  SET_EXPLORE_RISKS,
  REMOVE_RISKS,
} from "../actions/types";

const initialState = {
  flowCodes: null,
  countryCodes: null,
  risks: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RISKS_HOTSPOTS:
      return {
        ...state,
        [RISK_TYPES.RISKS_HOTSPOTS]: action.payload.data,
      };
    case SET_EXPLORE_RISKS:
      return {
        ...state,
        exploreRisks: action.payload.data,
      };
    case SET_COMPARE_RISKS:
      return {
        ...state,
        [RISK_TYPES.COMPARE_RISKS]: action.payload.data,
      };
    case SET_RISKS_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.data,
      };
    case REMOVE_RISKS:
      return {
        ...state,
        [action.payload.riskType]: null,
      };
    default:
      return state;
  }
};
