import { SET_LOADING, UNSET_LOADING, } from "../actions/types";

const initialState = { isLoading: false, };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: true, };
    case UNSET_LOADING:
      return { ...state, isLoading: false, };
    default:
      return state;
  }
};
