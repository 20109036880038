import store from "store";
import axios from "axios";
import { get, } from "lodash";
import { API_URL, } from "config/app";
import { REQUEST_METHODS, } from "utils/constants";
import { startFetching, stopFetching, } from "store/actions/sync";

axios.interceptors.response.use(
  response => {
    store.dispatch(stopFetching());
    return response.data;
  },
  error => {
    store.dispatch(stopFetching({ error, }));
    throw error;
  }
);

const call = ({ url, method = REQUEST_METHODS.GET, typeRequest, data, withoutToken, }) => {
  store.dispatch(startFetching({ meta: typeRequest, }));
  const token = localStorage.user && get(JSON.parse(localStorage.user), "token", "");

  axios.interceptors.request.use(
    config => ({
      ...config,
    }),
    error => {
      store.dispatch(stopFetching({ error, }));
      throw error;
    }
  );

  return axios({
    method,
    url: `${API_URL}/${url}`,
    data,
    headers: { token: !withoutToken && token, },
  });
};

export default call;
