import React, { Component, } from "react";
import { Alert, } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 75%;
  margin: 2% auto;
  text-align: center;
`;

export default class ErrorWrapper extends Component {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    this.setState({ error, });
  }

  render() {
    const { error, } = this.state;
    const { children, } = this.props;
    if (error) {
      return (
        <Wrapper>
          <Alert message="An error occured during render. Please, reload the page." type="error" />
        </Wrapper>
      );
    }

    return children;
  }
}
