import { SET_ANALYZE_FIELD, } from "../actions/types";

export default (state = {}, action) => {
  const { field, value, } = action.payload || {};
  switch (action.type) {
    case SET_ANALYZE_FIELD:
      return {
        ...state,
        [field]: value,
      };
    default:
      return state;
  }
};
