import React from "react";
import PropTypes from "prop-types";
import { Spin, } from "antd";
import { Wrapper, SpinWrap, } from "./styled-components";

const Loading = ({ onlySpinner, height, customStyles, }) =>
  onlySpinner ? (
    <SpinWrap style={customStyles}>
      <Spin />
    </SpinWrap>
  ) : (
    <Wrapper style={customStyles} height={height}>
      <Spin />
    </Wrapper>
  );

Loading.propTypes = {
  onlySpinner: PropTypes.bool, // show only spinner
  height: PropTypes.string,
};

Loading.defaultProps = {
  onlySpinner: false,
  height: "90%",
};

export default Loading;
