import React from "react";
import { Link, } from "react-router-dom";
import { Menu, } from "./styled-components";
import { getActiveLink, } from "../helpers";
import { defaultMenuList, } from "./config";

const Default = ({ path, menuLinkDisabled, }) => (
  <Menu theme="light" mode="horizontal" defaultSelectedKeys={getActiveLink(path)}>
    {defaultMenuList.map(menuItem => (
      <Menu.Item disabled={menuLinkDisabled && menuItem.key === "1"} key={menuItem.key}>
        {menuItem.link.startsWith("http") && (
          <a target="_black" href={menuItem.link}>
            {menuItem.name}
          </a>
        )}
        {!menuItem.link.startsWith("http") && (
          <Link to={{ pathname: menuItem.link, }}>{menuItem.name}</Link>
        )}
      </Menu.Item>
    ))}
  </Menu>
);

export default Default;
