export default function sortItems(a, b, byField) {
  if (a[byField] < b[byField]) {
    return -1;
  }
  if (a[byField] > b[byField]) {
    return 1;
  }

  return 0;
}
