import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button as AntButton, } from "antd";
import theme from "assets/theme";

const Button = styled(AntButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.green.default};
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 13px 30px;
  height: auto;
  max-height: 44px;
  box-shadow: 0 2px 4px 0 rgba(0, 28, 40, 0.1);
`;

export default props => {
  const { children, } = props;
  return <Button {...props}>{children}</Button>;
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
};
