import { get, } from "lodash";
import { COMPARE_FILTERS_TYPES, } from "utils/constants";
import {
  SET_FILTER,
  RESET_FILTERS,
  SELECT_COUNTRY,
  DESELECT_COUNTRY,
  RESET_COUNTRIES,
  SELECT_COMPARE_FILTER,
  DESELECT_COMPARE_FILTER,
  SET_COMPARE_AMOUNT,
} from "../actions/types";

const initialState = {
  sector: null,
  indicator: null,
  countries: [],
  compare: {
    filters: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        [action.payload.type]: action.payload.value,
      };
    case RESET_FILTERS:
      return {
        countries: state.countries,
      };
    case SELECT_COUNTRY: {
      const { country, index, } = action.payload;
      const countries = [...state.countries,];
      countries[index] = country;

      return {
        ...state,
        countries,
      };
    }
    case DESELECT_COUNTRY: {
      const countries = [...state.countries,];
      countries.splice(action.payload.index, 1);

      return {
        ...state,
        countries,
      };
    }
    case RESET_COUNTRIES: {
      return {
        ...state,
        countries: [],
      };
    }
    case SELECT_COMPARE_FILTER: {
      const { key, value, type, } = action.payload,
        filters = get(state.compare, "filters", {});
      let filter;

      switch (type) {
        case COMPARE_FILTERS_TYPES.COUNTRY: {
          filter = {
            country: value,
            sector: "",
          };
          break;
        }
        case COMPARE_FILTERS_TYPES.SECTOR: {
          filter = {
            ...filters[key],
            sector: value,
          };
          break;
        }
        default:
          filter = value;
      }

      return {
        ...state,
        compare: {
          ...state.compare,
          filters: {
            ...filters,
            [key]: filter,
          },
        },
      };
    }
    case DESELECT_COMPARE_FILTER: {
      const { key, } = action.payload,
        { filters, amount, } = state.compare;
      delete filters[key];

      return {
        ...state,
        compare: {
          amount,
          filters,
        },
      };
    }
    case SET_COMPARE_AMOUNT: {
      const { value, } = action.payload;

      return {
        ...state,
        compare: {
          ...state.compare,
          amount: value,
        },
      };
    }
    default:
      return state;
  }
};
