import React from "react";
import { Input, } from "./styled-components";

class InputComponent extends React.Component {
  componentDidMount() {
    const { defaultValue, onChange, } = this.props;
    if (defaultValue && onChange) {
      onChange(defaultValue);
    }
  }

  render() {
    const { onChange, } = this.props;
    return <Input {...this.props} onChange={e => onChange(e.target.value)} />;
  }
}

export default InputComponent;
