import {
  SET_RISKS_FIELD,
  SET_COMPARE_RISKS,
  SET_RISKS_HOTSPOTS,
  SET_EXPLORE_RISKS,
  REMOVE_RISKS,
} from "./types";

export const setRisksField = (data, field) => ({
  type: SET_RISKS_FIELD,
  payload: { data, field, },
});

export const setCompareRisks = data => ({
  type: SET_COMPARE_RISKS,
  payload: { data, },
});

export const setRisksHotspots = data => ({
  type: SET_RISKS_HOTSPOTS,
  payload: { data, },
});

export const setExploreRisks = data => ({
  type: SET_EXPLORE_RISKS,
  payload: { data, },
});

export const removeRisks = riskType => ({
  type: REMOVE_RISKS,
  payload: { riskType, },
});
