import { combineReducers, } from "redux";
import sectors from "./sectors";
import geography from "./geography";
import risks from "./risks";
import filters from "./filters";
import materials from "./materials";
import product from "./product";
import editableProduct from "./editableProduct";
import analyze from "./analyze";
import isComparison from "./isComparison";
import productTemplate from "./productTemplate";
import currentUser from "./currentUser";
import fetching from "./fetching";
import sync from "./sync";

export default combineReducers({
  sectors,
  geography,
  risks,
  editableProduct,
  isComparison,
  analyze,
  productTemplate,
  materials,
  product,
  filters,
  currentUser,
  fetching,
  sync,
});
