const paths = {
  root: "/",
  login: "/login",
  admin: "/admin",
  users: "/admin/users",
  compare: "/compare",
  trade: "/trade",
  countrySpecificSectors: "/trade/country-specific-sectors",
  myProducts: "/trade/my-products",
  createNewProduct: "/create-new-product",
  tradeCompare: "/trade/compare",
  analyze: "/analyze",
  inventory: "/analyze/inventory",
  network: "/analyze/network",
  socialHotspot: "/analyze/social-hotspot",
  socialFootprint: "/analyze/social-footprint",
};

export default paths;
